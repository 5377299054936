import { clamp, getRandom, lerp, normalize } from "../utils/utils.js";
import { Log } from "../utils/logger.js";
export class Color {
    constructor(color) {
        let hex;
        if (typeof color === "string") {
            hex = color;
            color = Color.hexToRGBA(color);
        }
        this.rgbaValue = color;
        this.hexValue = hex !== null && hex !== void 0 ? hex : Color.rgbaToHex(this.rgbaValue);
        this.rgbaString = Color.rgbaToString(this.rgbaValue);
        this.rgbaCSSString = Color.rgbaToCSSString(this.rgbaValue);
    }
    isEqual(color) {
        return (color.rgbaValue[0] === this.rgbaValue[0] &&
            color.rgbaValue[1] === this.rgbaValue[1] &&
            color.rgbaValue[2] === this.rgbaValue[2] &&
            color.rgbaValue[3] === this.rgbaValue[3]);
    }
    static Random() {
        return new Color(new Uint8ClampedArray([
            Math.floor(getRandom(0, 255)),
            Math.floor(getRandom(0, 255)),
            Math.floor(getRandom(0, 255)),
            255,
        ]));
    }
    static hexToRGBA(hex) {
        return new Uint8ClampedArray([
            parseInt(hex.slice(1, 3), 16),
            parseInt(hex.slice(3, 5), 16),
            parseInt(hex.slice(5, 7), 16),
            parseInt(hex.length > 7 ? hex.slice(7, 9) : "FF", 16),
        ]);
    }
    static _componentToHex(c) {
        let hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    }
    static rgbaToHex(rgba) {
        return ("#" +
            Color._componentToHex(rgba[0]) +
            Color._componentToHex(rgba[1]) +
            Color._componentToHex(rgba[2]) +
            Color._componentToHex(rgba[3]));
    }
    static rgbaToString(rgba) {
        return `${rgba[0]}:${rgba[1]}:${rgba[2]}:${rgba[3]}`;
    }
    static rgbaToCSSString(rgba) {
        return `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${normalize(rgba[3], 0, 255).toFixed(3)})`;
    }
    static scale(colors) {
        if (!Array.isArray(colors)) {
            Log.error("An array is expected to create a color scale");
            return;
        }
        if (colors.length <= 1) {
            Log.error("At least two colors are required to create a color scale");
            return;
        }
        let colorObjs = [];
        if (colors[0] instanceof Uint8ClampedArray || (Array.isArray(colors[0]) && typeof colors[0][0] === "number")) {
            colors.forEach((color) => colorObjs.push(new Color(color)));
        }
        else if (typeof colors[0] === "string") {
            colors.forEach((color) => colorObjs.push(new Color(Color.hexToRGBA(color))));
        }
        else {
            colorObjs = colors;
        }
        return (t) => {
            t = clamp(t, 0, 1);
            let index = (colorObjs.length - 1) * t;
            let start = Math.floor(index);
            let end = Math.ceil(index);
            t = index - start;
            let startColor = colorObjs[start], endColor = colorObjs[end];
            let result = startColor.rgbaValue.map((component, i) => {
                component = lerp(component, endColor.rgbaValue[i], t);
                if (i < 3)
                    component = Math.round(component);
                return component;
            });
            return "rgba(" + result + ")";
        };
    }
    serialize() {
        return [this.rgbaValue[0], this.rgbaValue[1], this.rgbaValue[2], this.rgbaValue[3]];
    }
    static create(data) {
        return new Color(data);
    }
}
