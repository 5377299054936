import { normalize, clamp } from "../utils/utils.js";
export class Vector {
    constructor(xOrDOMPoint, y) {
        if (xOrDOMPoint instanceof DOMPoint)
            [this.x, this.y] = [xOrDOMPoint.x, xOrDOMPoint.y];
        else
            [this.x, this.y] = [xOrDOMPoint || 0, y || 0];
    }
    addInPlace(arg1, arg2) {
        if (arg1 instanceof Vector) {
            this.x += arg1.x;
            this.y += arg1.y;
        }
        else if (typeof arg2 === "undefined") {
            this.x += arg1;
            this.y += arg1;
        }
        else {
            this.x += arg1;
            this.y += arg2;
        }
        return this;
    }
    multiplyInPlace(arg1, arg2) {
        if (arg1 instanceof Vector) {
            this.x *= arg1.x;
            this.y *= arg1.y;
        }
        else if (typeof arg2 === "undefined") {
            this.x *= arg1;
            this.y *= arg1;
        }
        else {
            this.x *= arg1;
            this.y *= arg2;
        }
        return this;
    }
    subtractInPlace(arg1, arg2) {
        if (arg1 instanceof Vector) {
            this.x -= arg1.x;
            this.y -= arg1.y;
        }
        else if (typeof arg2 === "undefined") {
            this.x -= arg1;
            this.y -= arg1;
        }
        else {
            this.x -= arg1;
            this.y -= arg2;
        }
        return this;
    }
    normalizeInPlace(min, max, minY, maxY) {
        if (typeof minY !== "undefined" && typeof maxY !== "undefined") {
            this.x = normalize(this.x, min, max);
            this.y = normalize(this.y, minY, maxY);
        }
        else {
            this.x = normalize(this.x, min, max);
            this.y = normalize(this.y, min, max);
        }
        return this;
    }
    clampInPlace(min, max, minY, maxY) {
        if (typeof minY !== "undefined" && typeof maxY !== "undefined") {
            this.x = clamp(this.x, min, max);
            this.y = clamp(this.y, minY, maxY);
        }
        else {
            this.x = clamp(this.x, min, max);
            this.y = clamp(this.y, min, max);
        }
        return this;
    }
    transformInPlace(transform) {
        let transformedPoint = transform.transformPoint(this);
        [this.x, this.y] = [transformedPoint.x, transformedPoint.y];
        return this;
    }
    absInPlace() {
        this.x = Math.abs(this.x);
        this.y = Math.abs(this.y);
        return this;
    }
    add(arg1, arg2) {
        return new Vector(this.x, this.y).addInPlace(arg1, arg2);
    }
    multiply(arg1, arg2) {
        return new Vector(this.x, this.y).multiplyInPlace(arg1, arg2);
    }
    subtract(arg1, arg2) {
        return new Vector(this.x, this.y).subtractInPlace(arg1, arg2);
    }
    normalize(min, max, minY, maxY) {
        return new Vector(this.x, this.y).normalizeInPlace(min, max, minY, maxY);
    }
    clamp(min, max, minY, maxY) {
        return new Vector(this.x, this.y).clampInPlace(min, max, minY, maxY);
    }
    isInside(arg1, arg2, arg3, arg4) {
        if (arg1 instanceof Vector && arg2 instanceof Vector) {
            if (this.x < arg1.x || this.x > arg2.x)
                return false;
            if (this.y < arg1.y || this.y > arg2.y)
                return false;
        }
        else if (typeof arg1 === "number" && typeof arg2 === "number") {
            if (this.x < arg1 || this.x > arg3)
                return false;
            if (this.y < arg2 || this.y > arg4)
                return false;
        }
        else {
            return false;
        }
        return true;
    }
    assign(arg1, arg2) {
        if (arg1 instanceof Vector) {
            this.x = arg1.x;
            this.y = arg1.y;
        }
        else if (typeof arg2 === "undefined") {
            this.x = arg1;
            this.y = arg1;
        }
        else {
            this.x = arg1;
            this.y = arg2;
        }
        return this;
    }
    transform(transform) {
        return new Vector(this.x, this.y).transformInPlace(transform);
    }
    abs() {
        return new Vector(this.x, this.y).absInPlace();
    }
    toString() {
        return "[" + this.x.toFixed(3) + ", " + this.y.toFixed(3) + "]";
    }
    clone() {
        return new Vector(this.x, this.y);
    }
    max() {
        return Math.max(this.x, this.y);
    }
    isEqual(vector, threshold) {
        if (typeof threshold !== "undefined") {
            return Vector.Distance(this, vector) <= threshold;
        }
        return this.x === vector.x && this.y === vector.y;
    }
    static Distance(vector1OrX1, vector2OrY1, x2, y2) {
        if (vector1OrX1 instanceof Vector && vector2OrY1 instanceof Vector) {
            return Math.sqrt(Math.pow(vector2OrY1.x - vector1OrX1.x, 2) + Math.pow(vector2OrY1.y - vector1OrX1.y, 2));
        }
        else if (typeof vector1OrX1 === "number" && typeof vector2OrY1 === "number") {
            return Math.sqrt(Math.pow(x2 - vector1OrX1, 2) + Math.pow(y2 - vector2OrY1, 2));
        }
        return -1;
    }
    static Midpoint(vector1, vector2) {
        return new Vector((vector1.x + vector2.x) / 2, (vector1.y + vector2.y) / 2);
    }
    static Zero() {
        return new Vector(0, 0);
    }
    static Random(minX, maxX, minY, maxY) {
        return new Vector(Math.random() * (maxX - minX) + minX, Math.random() * (maxY - minY) + minY);
    }
    serialize() {
        return { x: this.x, y: this.y };
    }
    static create(arg1, arg2) {
        if (typeof arg1 === "object") {
            return new Vector(arg1.x, arg1.y);
        }
        return new Vector(arg1, arg2);
    }
}
