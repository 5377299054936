import { TerminalType } from "../core/terminal.js";
import { UINode } from "./ui-node.js";
export class Display extends UINode {
    constructor(_node, options = DefaultDisplayOptions()) {
        super();
        this.displayConfigs = [];
        options = Object.assign(Object.assign({}, DefaultDisplayOptions()), options);
        const { height, style = {} } = options;
        this.height = height;
        this.draggable = true;
        this.zoomable = true;
        this.style = Object.assign(Object.assign({}, DefaultDisplayStyle()), style);
    }
    created(options) {
        options = Object.assign(Object.assign({}, DefaultDisplayOptions()), options);
        const { clear, customRenderers } = options;
        if (clear) {
            const terminal = this.createTerminal(TerminalType.IN, "event");
            terminal.on("event", () => {
                this.displayConfigs
                    .filter((config) => !config.rendererConfig.auto)
                    .forEach((config) => {
                    config.context.clearRect(0, 0, config.canvas.width, config.canvas.height);
                });
            });
        }
        if (typeof OffscreenCanvas !== "undefined") {
            customRenderers.forEach((rendererConfig) => {
                let offCanvas, offContext, newOffCanvasConfig;
                if (rendererConfig.canvasType === CanvasType.HTMLCanvasElement) {
                    offCanvas = document.createElement("canvas");
                    offCanvas.width = this.node.width - 2 * this.node.style.padding;
                    offCanvas.height = this.height;
                }
                else {
                    offCanvas = new OffscreenCanvas(this.node.width - 2 * this.node.style.padding, this.height);
                }
                offContext = offCanvas.getContext("2d");
                newOffCanvasConfig = { canvas: offCanvas, context: offContext, rendererConfig, shouldRender: true };
                this.displayConfigs.push(newOffCanvasConfig);
            });
        }
        else {
            customRenderers.forEach((rendererConfig) => {
                const offCanvas = document.createElement("canvas");
                offCanvas.width = this.node.width - 2 * this.node.style.padding;
                offCanvas.height = this.height;
                const offContext = offCanvas.getContext("2d");
                const newOffCanvasConfig = { canvas: offCanvas, context: offContext, rendererConfig, shouldRender: true };
                this.displayConfigs.push(newOffCanvasConfig);
            });
        }
        this.node.flow.flowConnect.on("scale", () => this.reflow());
    }
    customRender(canvasConfigs) {
        return Promise.all(canvasConfigs.map((config) => {
            return new Promise((resolve) => {
                if (config.shouldRender) {
                    if (config.rendererConfig.clear)
                        config.context.clearRect(0, 0, config.canvas.width, config.canvas.height);
                    if (this.style.backgroundColor) {
                        config.context.fillStyle = this.style.backgroundColor;
                        config.context.fillRect(0, 0, config.canvas.width, config.canvas.height);
                    }
                    resolve(config.rendererConfig.renderer(config.context, config.canvas.width, config.canvas.height));
                }
                else
                    resolve(false);
            });
        }));
    }
    paint() {
        let context = this.context;
        context.strokeStyle = this.style.borderColor;
        context.lineWidth = 1;
        context.strokeRect(this.position.x, this.position.y, this.width, this.height);
        let autoOffCanvasConfigs = this.displayConfigs.filter((config) => config.rendererConfig.auto);
        this.customRender(autoOffCanvasConfigs).then((results) => {
            results.forEach((result, index) => (autoOffCanvasConfigs[index].shouldRender = result));
        });
        this.displayConfigs.forEach((offCanvas) => {
            context.drawImage(offCanvas.canvas, 0, 0, offCanvas.canvas.width, offCanvas.canvas.height, this.position.x, this.position.y, this.node.width - 2 * this.node.style.padding, this.height);
        });
    }
    paintLOD1() {
        let context = this.context;
        context.strokeStyle = this.style.borderColor;
        context.strokeRect(this.position.x, this.position.y, this.width, this.height);
        context.fillStyle = "lightgrey";
        context.fillRect(this.position.x, this.position.y, this.width, this.height);
    }
    offPaint() {
        this.offUIContext.fillStyle = this.hitColor.hexValue;
        this.offUIContext.fillRect(this.position.x, this.position.y, this.width, this.height);
    }
    reflow() {
        var _a;
        let newWidth = (this.node.width - 2 * this.node.style.padding) * this.node.flow.flowConnect.scale;
        let newHeight = this.height * this.node.flow.flowConnect.scale;
        this.displayConfigs.forEach((offCanvas) => {
            if (Math.floor(offCanvas.canvas.width) !== Math.floor(newWidth) ||
                Math.floor(offCanvas.canvas.height) !== Math.floor(newHeight)) {
                offCanvas.canvas.width = newWidth;
                offCanvas.canvas.height = newHeight;
                if (!offCanvas.shouldRender) {
                    offCanvas.rendererConfig.renderer(offCanvas.context, offCanvas.canvas.width, offCanvas.canvas.height);
                    offCanvas.shouldRender = false;
                }
            }
        });
        (_a = this.input) === null || _a === void 0 ? void 0 : _a.position.assign(this.node.position.x - this.node.style.terminalStripMargin - this.input.style.radius, this.position.y + this.height / 2);
    }
    onPropChange() { }
}
export var CanvasType;
(function (CanvasType) {
    CanvasType["OffscreenCanvas"] = "OffscreenCanvas";
    CanvasType["HTMLCanvasElement"] = "HTMLCanvasElement";
})(CanvasType || (CanvasType = {}));
const DefaultDisplayStyle = () => ({
    borderColor: "#000",
});
const DefaultDisplayOptions = () => ({ height: 70, customRenderers: [] });
