import { Vector } from "./vector.js";
import { canConnect, uuid } from "../utils/utils.js";
import { FlowState } from "./flow.js";
import { TerminalType } from "./terminal.js";
import { NodeState } from "./node.js";
import { Hooks } from "./hooks.js";
export class Connector extends Hooks {
    constructor() {
        super();
        this.renderer = () => null;
    }
    get data() {
        return this._data;
    }
    set data(data) {
        this.setData(data);
        if (this.flow.state !== FlowState.Stopped) {
            this.flow.executionGraph.setDirty(this.endNode);
        }
    }
    static create(flow, start, end, options = DefaultConnectorOptions()) {
        var _a, _b;
        const connector = new Connector();
        const { style = {}, id = uuid(), floatingTip } = options;
        connector.flow = flow;
        connector.style = Object.assign(Object.assign(Object.assign({}, DefaultConnectorStyle()), (flow.flowConnect.getDefaultStyle("connector") || {})), style);
        connector.id = id;
        connector.floatingTip = floatingTip;
        connector.start = start;
        connector.end = end;
        connector.startNode = (_a = connector.start) === null || _a === void 0 ? void 0 : _a.node;
        connector.endNode = (_b = connector.end) === null || _b === void 0 ? void 0 : _b.node;
        if (start && end) {
            connector.floatingTip = null;
            start.connectors.push(connector);
            if (end.connectors.length > 0) {
                end.connectors[0].disconnect();
                end.connectors[0] = connector;
            }
            else {
                end.connectors.push(connector);
            }
            flow.executionGraph.connect(connector.startNode, connector.endNode);
            start.onConnect(connector);
            end.onConnect(connector);
        }
        return connector;
    }
    disconnect() {
        let [startTerm, endTerm] = [this.start, this.end];
        this.flow.removeConnector(this.id);
        this.start.connectors.splice(this.start.connectors.findIndex((cntr) => cntr.id === this.id), 1);
        this.end.connectors.pop();
        this.flow.executionGraph.disconnect(startTerm.node, endTerm.node);
        this.start.onDisconnect(this, startTerm, endTerm);
        this.end.onDisconnect(this, startTerm, endTerm);
    }
    setData(data) {
        this._data = data;
        this.end && this.end.call("data", this.end, data);
    }
    canConnect(other) {
        let firstTerminal = !this.start ? this.end : this.start;
        let source, destination;
        if (firstTerminal.type === TerminalType.IN) {
            source = other;
            destination = firstTerminal;
        }
        else {
            source = firstTerminal;
            destination = other;
        }
        return canConnect(source, destination, this.flow.rules, this.flow.executionGraph);
    }
    render() {
        let context = this.flow.flowConnect.context;
        context.save();
        const scopeFlowConnect = this.flow.flowConnect.getRegisteredRenderer("connector");
        const scopeFlow = this.flow.renderers.connector;
        const scopeConnector = this.renderer;
        const renderFn = (scopeConnector && scopeConnector(this)) ||
            (scopeFlow && scopeFlow(this)) ||
            (scopeFlowConnect && scopeFlowConnect(this)) ||
            this._render;
        renderFn(context, this.getRenderParams(), this);
        context.restore();
        let offContext = this.flow.flowConnect.offContext;
        offContext.save();
        this._offRender();
        offContext.restore();
        this.call("render", this);
    }
    _render(context, params, connector) {
        let ax = params.start.x, ay = params.start.y, dx = params.end.x, dy = params.end.y;
        let offset = Vector.Distance(ax, ay, dx, dy);
        offset *= 0.2;
        let [bx, by] = [ax + offset, ay];
        let [cx, cy] = [dx - offset, dy];
        let [midx, midy] = [(bx + cx) / 2, (by + cy) / 2];
        if (connector.style.border) {
            context.strokeStyle = connector.style.borderColor;
            context.lineWidth = connector.style.width + 2;
            context.beginPath();
            context.moveTo(ax, ay);
            context.quadraticCurveTo(bx, by, midx, midy);
            context.moveTo(midx, midy);
            context.quadraticCurveTo(cx, cy, dx, dy);
            context.stroke();
        }
        context.strokeStyle = connector.style.color;
        context.lineWidth = connector.style.width;
        context.beginPath();
        context.moveTo(ax, ay);
        context.quadraticCurveTo(bx, by, midx, midy);
        context.moveTo(midx, midy);
        context.quadraticCurveTo(cx, cy, dx, dy);
        context.stroke();
    }
    _offRender() {
    }
    getRenderParams() {
        let start, end;
        if (this.start) {
            if (this.startNode.renderState.nodeState === NodeState.MAXIMIZED)
                start = this.start.position.serialize();
            else {
                start = this.startNode.position.serialize();
                start.x += this.startNode.width + this.startNode.style.terminalStripMargin + this.start.style.radius;
                start.y += this.startNode.style.titleHeight / 2;
            }
        }
        else
            start = this.floatingTip.serialize();
        if (this.end) {
            if (this.endNode.renderState.nodeState === NodeState.MAXIMIZED)
                end = this.end.position.serialize();
            else {
                end = this.endNode.position.serialize();
                end.x -= this.endNode.style.terminalStripMargin + this.end.style.radius;
                end.y += this.endNode.style.titleHeight / 2;
            }
        }
        else
            end = this.floatingTip.serialize();
        return { start, end };
    }
    serialize() {
        return {
            id: this.id,
            startId: this.start.ui ? this.startNode.outputsUI.findIndex((term) => this.start === term) : this.start.id,
            endId: this.end.ui ? this.endNode.inputsUI.findIndex((term) => this.end === term) : this.end.id,
            startNodeId: this.startNode.id,
            endNodeId: this.endNode.id,
            style: this.style,
        };
    }
}
let DefaultConnectorStyle = () => {
    return {
        width: 5,
        color: "#7fff00aa",
        border: true,
        borderColor: "grey",
    };
};
let DefaultConnectorOptions = () => {
    return {
        style: {},
        id: uuid(),
    };
};
