import { Hooks } from "../core/hooks.js";
export class List extends Hooks {
    constructor(comparator, source) {
        super();
        this.comparator = comparator;
        this.head = null;
        this.tail = null;
        this.length = 0;
        if (source)
            source.forEach((value) => this.append(value));
    }
    prepend(data) {
        let newNode = new ListNode(data);
        if (this.head === null) {
            this.head = newNode;
            this.tail = newNode;
        }
        else {
            this.head.prev = newNode;
            newNode.next = this.head;
            this.head = newNode;
        }
        this.length += 1;
        this.call("prepend", this, newNode);
        return newNode;
    }
    append(data) {
        let newNode = data instanceof ListNode ? data : new ListNode(data);
        if (this.tail === null) {
            this.head = newNode;
            this.tail = newNode;
        }
        else {
            this.tail.next = newNode;
            newNode.prev = this.tail;
            this.tail = newNode;
        }
        this.length += 1;
        this.call("append", this, newNode);
        return newNode;
    }
    removeFirst(hook = true) {
        let removed;
        if (this.head === null)
            return null;
        else if (this.length === 1) {
            removed = this.head.data;
            this.head = null;
            this.tail = null;
        }
        else {
            removed = this.head.data;
            this.head.next.prev = null;
            this.head = this.head.next;
        }
        this.length -= 1;
        if (hook)
            this.call("removefirst", this, removed);
        return removed;
    }
    removeLast() {
        let removed;
        if (this.tail === null)
            return null;
        else if (this.length === 1) {
            removed = this.tail.data;
            this.head = null;
            this.tail = null;
        }
        else {
            removed = this.tail.data;
            this.tail.prev.next = null;
            this.tail = this.tail.prev;
        }
        this.length -= 1;
        this.call("removelast", this, removed);
        return removed;
    }
    addAfter(data, node) {
        let newNode = new ListNode(data);
        newNode.next = node.next;
        newNode.prev = node;
        node.next = newNode;
        if (newNode.next)
            newNode.next.prev = newNode;
        if (node === this.tail)
            this.tail = newNode;
        this.length += 1;
        return newNode;
    }
    addBefore(data, node) {
        let newNode = new ListNode(data);
        newNode.prev = node.prev;
        newNode.next = node;
        node.prev = newNode;
        if (newNode.prev)
            newNode.prev.next = newNode;
        if (node === this.head)
            this.head = newNode;
        this.length += 1;
        return newNode;
    }
    delete(node) {
        if (this.length === 1) {
            this.head = null;
            this.tail = null;
        }
        else if (node === this.head)
            this.removeFirst();
        else if (node === this.tail)
            this.removeLast();
        else {
            if (node.prev)
                node.prev.next = node.next;
            if (node.next)
                node.next.prev = node.prev;
            this.length -= 1;
        }
    }
    searchHead(callback) {
        let curr = this.head;
        while (curr !== null) {
            if (callback(curr))
                return curr;
            curr = curr.next;
        }
        return null;
    }
    searchTail(callback) {
        let curr = this.tail;
        while (curr !== null) {
            if (callback(curr))
                return curr;
            curr = curr.prev;
        }
        return null;
    }
    get(index) {
        if (index >= this.length)
            return null;
        else if (index === 0)
            return this.head ? this.head.data : null;
        else if (index === this.length - 1)
            return this.tail ? this.tail.data : null;
        else {
            let count = 0;
            let curr = this.head;
            while (curr !== null) {
                if (count === index)
                    return curr.data;
                count += 1;
                curr = curr.next;
            }
            return null;
        }
    }
    forEach(callback) {
        let curr = this.head;
        while (curr !== null) {
            callback(curr);
            curr = curr.next;
        }
    }
    map(callback) {
        let mapped = [];
        this.forEach((node) => mapped.push(callback(node)));
        return mapped;
    }
    toArray() {
        let data = [];
        this.forEach((node) => data.push(node.data));
        return data;
    }
    toString() {
        return this.toArray().join(", ");
    }
}
export class ListNode {
    constructor(data) {
        this.prev = null;
        this.next = null;
        this.data = data ? data : null;
    }
}
