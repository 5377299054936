import { Vector } from "./vector.js";
import { uuid, canConnect } from "../utils/utils.js";
import { Color } from "./color.js";
import { Connector } from "./connector.js";
import { Hooks } from "./hooks.js";
import { Log } from "../utils/logger.js";
import { Constant } from "../resource/constants.js";
export class Terminal extends Hooks {
    constructor() {
        super();
        this.renderer = () => null;
        this.connectors = [];
        this.focus = false;
        this.position = Vector.Zero();
    }
    get ui() {
        return this._ui;
    }
    set ui(val) {
        this._ui = val;
    }
    get propName() {
        return this._propName;
    }
    set propName(propName) {
        if (!propName || propName === "") {
            Log.error("Failed to set prop name '" + propName + "', prop name is invalid");
            return;
        }
        this.bindToProp(propName);
    }
    static create(node, type, dataType, options = DefaultTerminalOptions()) {
        const terminal = new Terminal();
        const { name = "", hitColor, propName, style = {}, id = uuid(), ui = false } = options;
        terminal.node = node;
        terminal.type = type;
        terminal.dataType = dataType;
        terminal.name = name;
        if (propName)
            terminal._propName = propName;
        terminal.style = Object.assign(Object.assign(Object.assign({}, DefaultTerminalStyle()), (node.flow.flowConnect.getDefaultStyle("terminal") || {})), style);
        terminal.id = id;
        terminal.ui = ui;
        terminal.setHitColor(hitColor);
        if (terminal._propName)
            terminal.bindToProp(terminal._propName);
        if (terminal.type === TerminalType.IN) {
            terminal.on("data", (t, data) => {
                if (t.propName)
                    t.node.state[t.propName] = data;
            });
        }
        return terminal;
    }
    bindToProp(propName) {
        var _a;
        let oldPropName = this._propName;
        let newPropName = propName;
        (_a = this.watcherId) !== null && _a !== void 0 ? _a : this.node.unwatch(oldPropName, this.watcherId);
        this._propName = newPropName;
        this.watcherId = this.node.watch(newPropName, (_oldVal, newVal) => {
            if (this.type === TerminalType.OUT)
                this.setData(newVal);
        });
    }
    getData() {
        if (this.type === TerminalType.OUT) {
            Log.error("Cannot call 'getData' on output terminal");
            return;
        }
        if (this.connectors.length > 0)
            return this.connectors[0].data;
        return null;
    }
    setData(data) {
        if (this.type === TerminalType.IN) {
            Log.error("Cannot call 'setData' on input terminal");
            return;
        }
        this.connectors.forEach((connector) => (connector.data = data));
    }
    setHitColor(hitColor) {
        if (!hitColor) {
            hitColor = Color.Random();
            while (this.node.terminals.get(hitColor.rgbaString) || this.node.uiNodes.get(hitColor.rgbaString))
                hitColor = Color.Random();
        }
        this.hitColor = hitColor;
        this.node.terminals.set(this.hitColor.rgbaString, this);
    }
    render() {
        let context = this.node.context;
        context.save();
        let scopeNode = this.node.renderers.terminal;
        let scopeFlow = this.node.flow.renderers.terminal;
        let scopeFlowConnect = this.node.flow.flowConnect.getRegisteredRenderer("terminal");
        const scopeTerminal = this.renderer;
        const renderFn = (scopeTerminal && scopeTerminal(this)) ||
            (scopeNode && scopeNode(this)) ||
            (scopeFlow && scopeFlow(this)) ||
            (scopeFlowConnect && scopeFlowConnect(this)) ||
            this._render;
        renderFn(context, this.getRenderParams(), this);
        context.restore();
        this.offUIRender();
        this.call("render", this);
    }
    _render(context, params, terminal) {
        var _a;
        if (params.focus) {
            context.beginPath();
            context.arc(params.position.x, params.position.y, terminal.style.radius * 3, 0, Constant.TAU);
            context.fillStyle = terminal.style.outerFocusColor;
            context.fill();
        }
        if (terminal.dataType === "event") {
            context.beginPath();
            context.moveTo(params.position.x, params.position.y - terminal.style.radius * 1.3);
            context.lineTo(params.position.x + terminal.style.radius * 1.3, params.position.y);
            context.lineTo(params.position.x, params.position.y + terminal.style.radius * 1.3);
            context.lineTo(params.position.x - terminal.style.radius * 1.3, params.position.y);
            context.lineTo(params.position.x, params.position.y - terminal.style.radius * 1.3);
            context.closePath();
        }
        else {
            context.beginPath();
            context.arc(params.position.x, params.position.y, terminal.style.radius, 0, Constant.TAU);
        }
        context.fillStyle = params.focus
            ? terminal.style.focusColor
            : ((_a = terminal.node.flow.ruleColors[terminal.dataType]) === null || _a === void 0 ? void 0 : _a.hexValue) || terminal.style.color;
        context.strokeStyle = terminal.style.borderColor;
        context.shadowBlur = terminal.style.shadowBlur;
        context.shadowColor = terminal.style.shadowColor;
        context.fill();
        context.stroke();
    }
    getRenderParams() {
        return {
            focus: this.focus,
            position: this.position.serialize(),
        };
    }
    connect(otherTerminal, options) {
        if (this.type !== otherTerminal.type) {
            let start = this.type === TerminalType.OUT ? this : otherTerminal;
            let end = this.type === TerminalType.IN ? this : otherTerminal;
            if (end.connectors.length > 0 && start.connectors.includes(end.connectors[0]))
                return false;
        }
        let source, destination;
        if (this.type === TerminalType.IN) {
            source = otherTerminal;
            destination = this;
        }
        else {
            source = this;
            destination = otherTerminal;
        }
        if (canConnect(source, destination, this.node.flow.rules, this.node.flow.executionGraph)) {
            let connector = Connector.create(this.node.flow, source, destination, options);
            this.node.flow.connectors.set(connector.id, connector);
            return true;
        }
        else
            return false;
    }
    disconnect(connector) {
        if (this.type === TerminalType.IN) {
            this.connectors[0].disconnect();
            return true;
        }
        else {
            if (typeof connector === "string") {
                let cntr = this.connectors.find((currCntr) => currCntr.id === connector);
                if (!cntr) {
                    Log.error("Connector not found while disconnecting", connector);
                    return false;
                }
                cntr.disconnect();
            }
            else if (connector instanceof Connector) {
                if (!this.connectors.find((cntr) => cntr.id === connector.id)) {
                    Log.error("Connector cannot be disconnected from the terminal because its not associated with it", connector);
                    return false;
                }
                connector.disconnect();
            }
            else {
                this.connectors.forEach((cntr) => cntr.disconnect());
            }
            return true;
        }
    }
    offUIRender() {
        let context = this.node.offUIContext;
        context.save();
        context.beginPath();
        context.arc(this.position.x, this.position.y, this.style.radius + this.node.style.terminalStripMargin, 0, Constant.TAU);
        context.fillStyle = this.hitColor.rgbaCSSString;
        context.fill();
        context.restore();
    }
    isConnected() {
        return this.connectors.length > 0;
    }
    onEnter(screenPosition, realPosition) {
        this.call("enter", this, screenPosition, realPosition);
        this.focus = true;
        this.node.flow.flowConnect.cursor = "pointer";
    }
    onExit(screenPosition, realPosition) {
        this.call("exit", this, screenPosition, realPosition);
        this.focus = false;
        this.node.flow.flowConnect.cursor = "unset";
    }
    onDown(screenPosition, realPosition) {
        if (this.connectors.length > 0) {
            if (this.type === TerminalType.IN) {
                const start = this.connectors[0].start;
                this.disconnect();
                this.node.flow.setFloatingConnector(realPosition, start, "left");
                this.node.currHitTerminal = null;
            }
            else {
                if (this.node.flow.floatingConnector)
                    return;
                this.node.flow.setFloatingConnector(realPosition, this, "left");
            }
        }
        else {
            if (this.node.flow.floatingConnector)
                return;
            this.node.flow.setFloatingConnector(realPosition, this, this.type === TerminalType.IN ? "right" : "left");
        }
        this.call("down", this, screenPosition, realPosition);
    }
    onUp(screenPosition, realPosition) {
        this.call("up", this, screenPosition, realPosition);
    }
    onDrag(screenPosition, realPosition) {
        this.call("drag", this, screenPosition, realPosition);
    }
    onClick(screenPosition, realPosition) {
        this.call("click", this, screenPosition, realPosition);
    }
    onOver(screenPosition, realPosition) {
        this.call("over", this, screenPosition, realPosition);
    }
    onContextMenu() {
        this.call("rightclick", this);
    }
    onConnect(connector) {
        this.call("connect", this, connector);
    }
    onDisconnect(connector, startTerminal, endTerminal) {
        this.call("disconnect", this, connector, startTerminal, endTerminal);
    }
    onEvent(data) {
        if (this.type === TerminalType.IN) {
            this.call("event", this, data);
        }
    }
    emit(data) {
        if (this.type === TerminalType.OUT && this.connectors.length !== 0) {
            this.connectors.forEach((connector) => {
                connector.end && connector.end.onEvent(data);
            });
            this.call("emit", this, data);
        }
    }
    serialize() {
        return {
            name: this.name,
            type: this.type,
            dataType: this.dataType,
            propName: this.propName,
            id: this.id,
            style: this.style,
            hitColor: this.hitColor.serialize(),
            ui: this.ui,
        };
    }
}
export var TerminalType;
(function (TerminalType) {
    TerminalType[TerminalType["IN"] = 1] = "IN";
    TerminalType[TerminalType["OUT"] = 2] = "OUT";
})(TerminalType || (TerminalType = {}));
let DefaultTerminalStyle = () => {
    return {
        radius: 4,
        color: "#888",
        borderColor: "#222",
        shadowBlur: 0,
        shadowColor: "#ccc",
        focusColor: "#00ff00",
        outerFocusColor: "#bbbbbb80",
    };
};
const DefaultTerminalOptions = () => {
    return {
        name: "",
        style: {},
    };
};
