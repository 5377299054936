var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Log } from "../utils/logger.js";
import { uuid } from "../utils/utils.js";
import { FlowState } from "./flow.js";
import { Node } from "./node.js";
import { List } from "../utils/linked-list.js";
export class Graph {
    constructor() {
        this.state = FlowState.Stopped;
        this.nodes = [];
        this.graphNodes = new Map();
        this.dirtyNodes = new Map();
    }
    add(data) {
        if (!this.nodes[0])
            this.nodes.push([]);
        let graphNode = new GraphNode(data);
        this.nodes[0].push(graphNode);
        this.graphNodes.set(data.id, graphNode);
    }
    remove(data) {
        let graphNode = this.graphNodes.get(data.id);
        this.dirtyNodes.delete(graphNode.id);
        this.graphNodes.delete(data.id);
        this.nodes[0].splice(this.nodes[0].findIndex((node) => node.id === graphNode.id), 1);
    }
    connect(sourceNode, destinationNode) {
        let startGraphNode = this.graphNodes.get(sourceNode.id);
        let endGraphNode = this.graphNodes.get(destinationNode.id);
        if (!startGraphNode.childs.includes(endGraphNode)) {
            startGraphNode.childs.push(endGraphNode);
            if (endGraphNode.order <= startGraphNode.order) {
                this.updateOrder(endGraphNode, startGraphNode.order + 1);
            }
        }
    }
    disconnect(sourceNode, destinationNode) {
        let sourceGraphNode = this.graphNodes.get(sourceNode.id);
        let destinationGraphNode = this.graphNodes.get(destinationNode.id);
        let connectedEndNodes = new Set();
        sourceNode.outputs.forEach((terminal) => terminal.connectors.forEach((connector) => connector.endNode && connectedEndNodes.add(connector.endNode)));
        if (connectedEndNodes.has(destinationNode))
            return;
        sourceGraphNode.childs.splice(sourceGraphNode.childs.indexOf(destinationGraphNode), 1);
        let maxOrderOfConnectedStartNodes = destinationNode.inputs
            .filter((terminal) => terminal.connectors.length > 0)
            .map((terminal) => this.graphNodes.get(terminal.connectors[0].startNode.id).order)
            .reduce((acc, curr) => Math.max(acc, curr), 0);
        this.updateOrder(destinationGraphNode, maxOrderOfConnectedStartNodes);
    }
    updateOrder(root, order) {
        this._updateOrder(root, order);
        let queue = [];
        queue.push(root);
        while (queue.length !== 0) {
            let currNode = queue.shift();
            currNode.childs.forEach((child) => {
                if (child.order <= currNode.order) {
                    this._updateOrder(child, currNode.order + 1);
                }
                if (!queue.includes(child))
                    queue.push(child);
            });
        }
    }
    _updateOrder(graphNode, order) {
        this.nodes[graphNode.order].splice(this.nodes[graphNode.order].indexOf(graphNode), 1);
        if (!this.nodes[order])
            this.nodes[order] = [];
        graphNode.order = order;
        this.nodes[order].push(graphNode);
    }
    canConnect(sourceNode, destinationNode) {
        if (this.graphNodes.get(destinationNode.id).childs.includes(this.graphNodes.get(sourceNode.id)))
            return false;
        return true;
    }
    start() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.state === FlowState.Stopped) {
                this.state = FlowState.Running;
                if (this.nodes[0]) {
                    try {
                        yield this.runAll(this.nodes[0]);
                        while (this.dirtyNodes.size !== 0) {
                            yield this.runAll(this.lowestDirty([...this.dirtyNodes.values()]));
                        }
                    }
                    catch (error) {
                        Log.error("Error while executing graph", error);
                        this.state = FlowState.Stopped;
                        return;
                    }
                }
            }
            else if (this.state === FlowState.Idle) {
                this.state = FlowState.Running;
                try {
                    while (this.dirtyNodes.size !== 0) {
                        yield this.runAll(this.lowestDirty([...this.dirtyNodes.values()]));
                    }
                }
                catch (error) {
                    Log.error("Error while executing graph", error);
                    this.state = FlowState.Stopped;
                    return;
                }
            }
            this.state = FlowState.Idle;
        });
    }
    stop() {
        this.state = FlowState.Stopped;
    }
    runAll(graphNodes) {
        return __awaiter(this, void 0, void 0, function* () {
            yield Promise.all(graphNodes.map((graphNode) => new Promise((resolve) => {
                graphNode.flowNode.run();
                this.clearDirty(graphNode);
                resolve();
            })));
        });
    }
    setDirty(node) {
        let graphNode = node instanceof Node ? this.graphNodes.get(node.id) : node;
        if (!graphNode)
            return;
        this.dirtyNodes.set(graphNode.id, graphNode);
    }
    clearDirty(node) {
        let graphNode = node instanceof Node ? this.graphNodes.get(node.id) : node;
        this.dirtyNodes.delete(graphNode.id);
    }
    lowestDirty(graphNodes) {
        let lowestOrder = Math.min(...graphNodes.map((graphNode) => graphNode.order));
        return graphNodes.filter((graphNode) => graphNode.order === lowestOrder);
    }
    propagate(root, callback) {
        let start = root instanceof Node ? this.graphNodes.get(root.id) : root;
        let queue = new List();
        queue.append(start);
        while (queue.length !== 0) {
            let currGNode = queue.removeFirst();
            callback(currGNode.flowNode);
            currGNode.childs.forEach((child) => queue.append(child));
        }
    }
    debugNode(node, indent) {
        Log.info(`${indent}[${node.flowNode.name}, ${node.order}]`);
        node.childs.forEach((child) => this.debugNode(child, indent + "  "));
    }
    debugGraph() {
        this.nodes[0].forEach((graphNode) => {
            this.debugNode(graphNode, "");
        });
    }
}
export class GraphNode {
    constructor(flowNode, order, id) {
        this.flowNode = flowNode;
        this.childs = [];
        this.order = order ? order : 0;
        this.id = id ? id : uuid();
    }
}
