import { ViewPort } from "../common/enums.js";
export const uuid = () => crypto.randomUUID();
export const normalize = (value, min, max) => {
    if (min === max)
        return 1;
    return (value - min) / (max - min);
};
export const denormalize = (normalized, min, max) => {
    return (max - min) * normalized + min;
};
export const getRandom = (min, max) => {
    return Math.random() * (max - min) + min;
};
export const intersects = (start1X, start1Y, end1X, end1Y, start2X, start2Y, end2X, end2Y) => {
    let res = !(start2X > end1X || end2X < start1X || start2Y > end1Y || end2Y < start1Y);
    if (res) {
        if (!(start2X < start1X || start2Y < start1Y || end2X > end1X || end2Y > end1Y))
            return ViewPort.INSIDE;
        else
            return ViewPort.INTERSECT;
    }
    else
        return ViewPort.OUTSIDE;
};
export const clampMin = (value, min) => Math.max(value, min);
export const clampMax = (value, max) => Math.min(value, max);
export const clamp = (value, min, max) => Math.min(Math.max(value, min), max);
export const lerp = (a, b, t) => (1 - t) * a + t * b;
export const canConnect = (source, destination, rules, executionGraph) => {
    if (!destination)
        return false;
    if (source === destination)
        return false;
    if (source.node === destination.node)
        return false;
    if (source.type === destination.type)
        return false;
    if (!rules[source.dataType].includes(destination.dataType))
        return false;
    if (!executionGraph.canConnect(source.node, destination.node))
        return false;
    return true;
};
export const isEmpty = (obj) => {
    for (let _ in obj)
        return false;
    return true;
};
export const isInRange = (value, min, max) => {
    return Math.min(value, min) >= min && Math.max(value, max) <= max;
};
export const exists = (value) => typeof value !== "undefined" && value !== null;
export const binarySearch = ({ max, getValue, match }) => {
    let min = 0;
    while (min <= max) {
        let mid = Math.floor((min + max) / 2);
        const curr = getValue(mid);
        if (curr === match)
            return mid;
        if (curr < match)
            min = mid + 1;
        else
            max = mid - 1;
    }
    return max;
};
export const noop = () => {
};
export const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);
export const cloneAudioBuffer = (from) => {
    const cloned = new AudioBuffer({
        length: from.length,
        numberOfChannels: from.numberOfChannels,
        sampleRate: from.sampleRate,
    });
    for (let i = 0; i < cloned.numberOfChannels; ++i) {
        cloned.copyToChannel(from.getChannelData(i), i);
    }
    return cloned;
};
export const isVector = (obj) => {
    return (typeof obj === "object" &&
        obj &&
        Object.keys(obj).every((k) => ["x", "y"].includes(k) && typeof obj[k] === "number"));
};
