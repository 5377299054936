export class BiMap {
    constructor() {
        this.map = new Map();
        this.reverseMap = new Map();
    }
    get(keyOrVal) {
        return this.map.get(keyOrVal) || this.reverseMap.get(keyOrVal);
    }
    set(key, value) {
        this.map.set(key, value);
        this.reverseMap.set(value, key);
    }
    has(keyOrVal) {
        return this.map.has(keyOrVal) || this.reverseMap.has(keyOrVal);
    }
    delete(keyOrVal) {
        let temp1 = this.map.get(keyOrVal);
        let temp2 = this.reverseMap.get(keyOrVal);
        if (typeof temp1 !== 'undefined') {
            this.map.delete(keyOrVal);
        }
        if (typeof temp2 !== 'undefined') {
            this.reverseMap.delete(keyOrVal);
        }
        this.map.delete(temp1);
        this.reverseMap.delete(temp1);
        this.map.delete(temp2);
        this.reverseMap.delete(temp2);
    }
    clear() {
        this.map.clear();
        this.reverseMap.clear();
    }
}
