export var LogLevel;
(function (LogLevel) {
    LogLevel[LogLevel["TRACE"] = 1] = "TRACE";
    LogLevel[LogLevel["DEBUG"] = 2] = "DEBUG";
    LogLevel[LogLevel["WARN"] = 3] = "WARN";
    LogLevel[LogLevel["INFO"] = 4] = "INFO";
    LogLevel[LogLevel["LOG"] = 5] = "LOG";
    LogLevel[LogLevel["ERROR"] = 6] = "ERROR";
    LogLevel[LogLevel["DISABLED"] = 7] = "DISABLED";
})(LogLevel || (LogLevel = {}));
export class Log {
    static trace(...args) {
        if (this.LOG_LEVEL > LogLevel.TRACE)
            return;
        console.trace.apply(this, args);
    }
    static debug(...args) {
        if (this.LOG_LEVEL > LogLevel.DEBUG)
            return;
        console.debug.apply(this, args);
    }
    static warn(...args) {
        if (this.LOG_LEVEL > LogLevel.WARN)
            return;
        console.warn.apply(this, args);
    }
    static info(...args) {
        if (this.LOG_LEVEL > LogLevel.INFO)
            return;
        console.info.apply(this, args);
    }
    static log(...args) {
        if (this.LOG_LEVEL > LogLevel.LOG)
            return;
        console.log.apply(this, args);
    }
    static error(...args) {
        if (this.LOG_LEVEL > LogLevel.ERROR)
            return;
        console.error.apply(this, args);
    }
}
Log.LOG_LEVEL = LogLevel.INFO;
