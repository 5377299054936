import { Vector } from "../core/vector.js";
import { UINode } from "./ui-node.js";
import { Align } from "../common/enums.js";
export class Container extends UINode {
    constructor(_node, _options) {
        super();
        this.renderer = () => null;
    }
    created(options) {
        options = Object.assign(Object.assign({}, DefaultContainerOptions(this.node)), options);
        const { width, height, style = {} } = options;
        this.width = width;
        this.height = height !== null && height !== void 0 ? height : this.node.style.padding * 2;
        this.contentWidth = width - 2 * this.node.style.padding;
        this.style = Object.assign(Object.assign({}, DefaultContainerStyle()), style);
        this.position = this.node.position;
    }
    paint() {
        const context = this.context;
        const scopeFlowConnect = this.node.flow.flowConnect.getRegisteredRenderer("background");
        const scopeFlow = this.node.flow.renderers.background;
        const scopeNode = this.node.renderers.background;
        const scopeContainer = this.renderer;
        const renderFn = (scopeContainer && scopeContainer(this)) ||
            (scopeNode && scopeNode(this)) ||
            (scopeFlow && scopeFlow(this)) ||
            (scopeFlowConnect && scopeFlowConnect(this)) ||
            this._paint;
        renderFn(context, this.getRenderParams(), this);
    }
    _paint(context, params, container) {
        context.shadowColor = container.style.shadowColor;
        context.shadowBlur = container.style.shadowBlur;
        context.shadowOffsetX = container.style.shadowOffset.x;
        context.shadowOffsetY = container.style.shadowOffset.y;
        context.fillStyle = container.style.backgroundColor;
        context.strokeStyle = container.style.borderColor;
        context.lineWidth = container.style.borderWidth;
        context.roundRect(params.position.x, params.position.y, params.width, params.height, 5);
        context.stroke();
        context.fill();
    }
    paintLOD1() {
        let context = this.context;
        context.fillStyle = this.style.backgroundColor;
        context.strokeStyle = this.style.borderColor;
        context.lineWidth = this.style.borderWidth;
        context.roundRect(this.position.x, this.position.y + this.node.style.titleHeight, this.width, this.height - this.node.style.titleHeight, 5);
        context.stroke();
        context.fill();
    }
    offPaint() {
        this.offUIContext.fillStyle = this.hitColor.hexValue;
        this.offUIContext.fillRect(this.position.x, this.position.y, this.width, this.height);
    }
    getRenderParams() {
        let position = this.position.serialize();
        position.y += this.node.style.titleHeight;
        return {
            position: position,
            width: this.width,
            height: this.height - this.node.style.titleHeight,
        };
    }
    reflow() {
        const nodeStyle = this.node.style;
        this.position = this.node.position;
        let terminalsDisplayHeight = Math.max(this.node.inputs.length, this.node.outputs.length) * nodeStyle.terminalRowHeight + nodeStyle.titleHeight;
        let x = this.position.x + nodeStyle.padding;
        let y = this.position.y + terminalsDisplayHeight;
        this.children
            .filter((child) => child.visible)
            .forEach((child) => {
            y += nodeStyle.spacing;
            let availableWidth = this.width - nodeStyle.padding * 2;
            child.width = (child.width > availableWidth ? availableWidth : child.width) || availableWidth;
            if (child.width < availableWidth) {
                let childX;
                if (child.style.align === Align.Center) {
                    childX = this.position.x + this.width / 2 - child.width / 2;
                }
                else if (child.style.align === Align.Right) {
                    childX = this.position.x + this.width - nodeStyle.padding - child.width;
                }
                else {
                    childX = x;
                }
                child.position = Vector.create(childX, y);
            }
            else {
                child.position = Vector.create(x, y);
            }
            y += child.height;
        });
        this.height = y + nodeStyle.padding - this.position.y;
    }
    onPropChange() { }
}
const DefaultContainerStyle = () => ({
    backgroundColor: "#ddd",
    shadowColor: "#666",
    shadowBlur: 3,
    shadowOffset: Vector.create(3, 3),
    borderWidth: 1,
    borderColor: "#444",
});
let DefaultContainerOptions = (node) => ({
    width: node.width,
});
