var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Flow } from "./core/flow.js";
import { Group } from "./core/group.js";
import { Color } from "./core/color.js";
import { Hooks } from "./core/hooks.js";
import { Vector } from "./core/vector.js";
import { cloneAudioBuffer, intersects, noop } from "./utils/utils.js";
import { Log } from "./utils/logger.js";
import { TerminalType } from "./core/terminal.js";
import { FlowState } from "./core/flow.js";
import { ViewPort } from "./common/enums.js";
import { generateAudioWorklets, generateWorkletUtils } from "./resource/audio-worklets.js";
import { TunaInitializer } from "./lib/tuna.js";
import { EmptyNode } from "./core/empty-node.js";
import { SubFlowNode } from "./core/subflow-node.js";
import { TunnelNode } from "./core/tunnel-node.js";
import { Button, Label, Dial, Display, Envelope, HorizontalLayout, Image, Input, RadioGroup, Select, Slider2D, Slider, Source, Stack, Toggle, VSlider, Container, } from "./flow-connect.js";
export class FlowConnect extends Hooks {
    constructor(mount) {
        super();
        this.version = process.env.FLOWCONNECT_VERSION;
        this.caches = {
            array: new Map(),
            audio: new Map(),
        };
        this.canvasDimensions = { top: 0, left: 0, width: 0, height: 0 };
        this.state = FlowConnectState.Stopped;
        this.flows = [];
        this.pointers = [];
        this.keymap = {};
        this.touchControls = { CreateGroup: false };
        this.genericInput = document.createElement("input");
        this.minScale = 0.1;
        this.maxScale = 5;
        this.wheelScaleDelta = 1.05;
        this.pinchScaleDelta = 1.02;
        this.disableScale = false;
        this._transform = new DOMMatrix();
        this.inverseTransform = new DOMMatrix();
        this.identity = new DOMMatrix();
        this.startTime = -1;
        this.renderers = {};
        this.defaultStyles = {
            global: {
                node: {},
                ui: {},
            },
            node: {},
            ui: {},
            connector: {},
            terminal: {},
            group: {},
        };
        this.throttle = false;
        this.prepareCanvas(mount);
        this.setupHitCanvas();
        this.calcCanvasDimension(true);
        this.registerChangeListeners();
        this.attachStyles();
        this.canvasUtils();
        this.registerEvents();
        this.setGenericInput();
    }
    static create(mount) {
        return __awaiter(this, void 0, void 0, function* () {
            let flowConnect = new FlowConnect(mount);
            yield flowConnect.setupAudioContext();
            if (!window.__tuna__)
                window.__tuna__ = new (TunaInitializer.initialize())(flowConnect.audioContext);
            return flowConnect;
        });
    }
    static register(metadata, executor) {
        if (!metadata.name)
            return false;
        if (this.plugins[metadata.type][metadata.name])
            return false;
        this.plugins[metadata.type][metadata.name] = executor;
        return true;
    }
    static getRegistered(type, name) {
        return this.plugins[type][name];
    }
    get context() {
        return this._context;
    }
    get audioContext() {
        return this._audioContext;
    }
    setCache(type, key, cache) {
        if (type === "array") {
            this.caches[type].set(key, cache.slice(0));
        }
        else if (type === "audio") {
            this.caches[type].set(key, cloneAudioBuffer(cache));
        }
        else {
            this.caches[type].set(key, cache);
        }
    }
    getCache(type, key) {
        const cache = this.caches[type].get(key);
        if (!cache)
            return null;
        if (type === "array") {
            return cache.slice(0);
        }
        else if (type === "audio") {
            return cloneAudioBuffer(cache);
        }
        return cache;
    }
    get offContext() {
        return this._offContext;
    }
    get offUIContext() {
        return this._offUIContext;
    }
    get offGroupContext() {
        return this._offGroupContext;
    }
    get cursor() {
        return this.canvas.style.cursor;
    }
    set cursor(cursor) {
        this.canvas.style.cursor = cursor;
    }
    get scale() {
        return this._transform.a;
    }
    get transform() {
        return this._transform;
    }
    get time() {
        return this.startTime < 0 ? this.startTime : performance.now() - this.startTime;
    }
    registerRenderer(type, renderer) {
        if (!type || !renderer)
            return false;
        this.renderers[type] = renderer;
        return true;
    }
    getRegisteredRenderer(type) {
        return this.renderers[type];
    }
    setDefaultStyle(type, nameOrStyle, style) {
        if ((type === "node" || type === "ui") && typeof nameOrStyle === "string") {
            this.defaultStyles[type][nameOrStyle] = style;
        }
        else if ((type === "node" || type === "ui") && typeof nameOrStyle === "object") {
            this.defaultStyles.global[type] = nameOrStyle;
        }
        else if (type !== "node" && type !== "ui") {
            this.defaultStyles[type] = nameOrStyle;
        }
    }
    getDefaultStyle(type, name) {
        var _a;
        if (type === "node" || type === "ui") {
            return ((_a = this.defaultStyles[type][name]) !== null && _a !== void 0 ? _a : this.defaultStyles.global[type]);
        }
        else {
            return this.defaultStyles[type];
        }
    }
    registerChangeListeners() {
        document.addEventListener("scroll", this.scrollListener.bind(this));
        this.registerObservers(this.canvas.parentElement);
    }
    deRegisterChangeListeners() {
        document.removeEventListener("scroll", this.scrollListener);
    }
    scrollListener() {
        if (!this.throttle) {
            window.requestAnimationFrame(() => {
                this.calcCanvasDimension(false);
                this.throttle = false;
            });
            this.throttle = true;
        }
    }
    registerObservers(parent) {
        this.deRegisterObservers();
        this.parentResizeObserver = new ResizeObserver(() => {
            this.calcCanvasDimension(true);
            this.updateTransform(null, null, null);
        });
        this.parentResizeObserver.observe(parent);
        if (parent !== document.body) {
            this.bodyResizeObserver = new ResizeObserver(() => {
                this.calcCanvasDimension(true);
                this.updateTransform(null, null, null);
            });
            this.bodyResizeObserver.observe(document.body);
        }
    }
    deRegisterObservers() {
        this.parentResizeObserver && this.parentResizeObserver.disconnect();
        this.bodyResizeObserver && this.bodyResizeObserver.disconnect();
    }
    prepareCanvas(mount) {
        if (!mount) {
            this.canvas = document.createElement("canvas");
            this.canvas.width = document.body.clientWidth;
            this.canvas.height = document.body.clientHeight;
            document.body.appendChild(this.canvas);
        }
        else if (mount instanceof HTMLDivElement) {
            this.canvas = document.createElement("canvas");
            this.canvas.width = mount.clientWidth;
            this.canvas.height = mount.clientHeight;
            mount.appendChild(this.canvas);
        }
        else if (mount instanceof HTMLCanvasElement) {
            this.canvas = mount;
        }
        else {
            Log.error("'mount' provided is not of type HTMLDivElement or HTMLCanvasElement");
        }
        Object.assign(this.canvas.style, {
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
        });
        this._context = this.canvas.getContext("2d");
    }
    setupHitCanvas() {
        if (typeof OffscreenCanvas !== "undefined" && typeof OffscreenCanvasRenderingContext2D !== "undefined") {
            this.offCanvas = new OffscreenCanvas(this.canvasDimensions.width, this.canvasDimensions.height);
            this.offUICanvas = new OffscreenCanvas(this.canvasDimensions.width, this.canvasDimensions.height);
            this.offGroupCanvas = new OffscreenCanvas(this.canvasDimensions.width, this.canvasDimensions.height);
        }
        else {
            this.offCanvas = document.createElement("canvas");
            this.offUICanvas = document.createElement("canvas");
            this.offGroupCanvas = document.createElement("canvas");
        }
        this._offContext = this.offCanvas.getContext("2d", { willReadFrequently: true });
        this._offUIContext = this.offUICanvas.getContext("2d", { willReadFrequently: true });
        this._offGroupContext = this.offGroupCanvas.getContext("2d", { willReadFrequently: true });
    }
    attachStyles() {
        this.canvas.style.touchAction = "none";
        let inputStyle = document.createElement("style");
        inputStyle.innerHTML =
            "input.flow-connect-input { position: fixed; visibility: hidden; pointer-events: none; z-index: 100; border: none; border-radius: 0; box-sizing: border-box;} input.flow-connect-input:focus { outline: none; }";
        document.getElementsByTagName("head")[0].appendChild(inputStyle);
    }
    calcCanvasDimension(adjust) {
        if (adjust && this.canvas.parentElement) {
            let parentBoundingRect = this.canvas.parentElement.getBoundingClientRect();
            this.canvas.width = parentBoundingRect.width;
            this.canvas.height = parentBoundingRect.height;
            this.call("dimension-change", this, this.canvas.width, this.canvas.height);
        }
        let boundingRect = this.canvas.getBoundingClientRect();
        this.canvasDimensions = {
            top: Math.round(boundingRect.top),
            left: Math.round(boundingRect.left),
            width: Math.round(boundingRect.width),
            height: Math.round(boundingRect.height),
        };
        this.offCanvas.width = this.canvasDimensions.width;
        this.offCanvas.height = this.canvasDimensions.height;
        this.offUICanvas.width = this.canvasDimensions.width;
        this.offUICanvas.height = this.canvasDimensions.height;
        this.offGroupCanvas.width = this.canvasDimensions.width;
        this.offGroupCanvas.height = this.canvasDimensions.height;
    }
    canvasUtils() {
        FlowConnect.prototype.canvasUtils = noop;
        CanvasRenderingContext2D.prototype.roundRect = function (x, y, width, height, radius) {
            this.beginPath();
            this.moveTo(x + radius, y);
            this.lineTo(x + width - radius, y);
            this.quadraticCurveTo(x + width, y, x + width, y + radius);
            this.lineTo(x + width, y + height - radius);
            this.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
            this.lineTo(x + radius, y + height);
            this.quadraticCurveTo(x, y + height, x, y + height - radius);
            this.lineTo(x, y + radius);
            this.quadraticCurveTo(x, y, x + radius, y);
            this.closePath();
        };
        CanvasRenderingContext2D.prototype.strokeRoundRect = function (x, y, width, height, radius) {
            if (typeof radius === "undefined")
                radius = 5;
            this.roundRect(x, y, width, height, radius);
            this.stroke();
        };
        CanvasRenderingContext2D.prototype.fillRoundRect = function (x, y, width, height, radius) {
            if (typeof radius === "undefined")
                radius = 5;
            this.roundRect(x, y, width, height, radius);
            this.fill();
        };
    }
    handleKeyDown(ev) {
        !this.keymap[ev.key] && (this.keymap[ev.key] = true);
    }
    registerEvents() {
        let dragDelta;
        let prevPanPosition = Vector.Zero();
        let prevPinchDistance = -1;
        let longPressTimerId = -1;
        let longPressPointer = null;
        document.onkeydown = (ev) => this.handleKeyDown(ev);
        document.onkeyup = (ev) => {
            this.keymap[ev.key] = false;
        };
        this.canvas.onpointerdown = (ev) => {
            if (!this.currFlow)
                return;
            this.addPointer(ev.pointerId, this.getRelativePosition(ev));
            if (this.pointers.length === 1) {
                prevPanPosition = this.pointers[0].screenPosition;
                this.currHitNode = this.getHitNode(this.pointers[0].screenPosition);
                if (this.currHitNode) {
                    this.currHitNode.zIndex = Number.MAX_SAFE_INTEGER;
                    if (this.keymap["Control"]) {
                        this.currHitNode.focused = !this.currHitNode.focused;
                    }
                    else {
                        this.currFlow.removeAllFocus();
                        this.currHitNode.focused = true;
                    }
                    this.currHitNode.onDown(this.pointers[0].screenPosition.clone(), this.pointers[0].realPosition.clone());
                    dragDelta = this.currHitNode.position.subtract(this.pointers[0].realPosition);
                    if (!this.currHitNode.currHitUINode) {
                        longPressTimerId = window.setTimeout(() => {
                            this.currHitNode.onContextMenu(this.pointers[0].screenPosition, this.pointers[0].realPosition);
                            this.call("context-menu", {
                                screenPos: this.pointers[0].screenPosition,
                                realPos: this.pointers[0].realPosition,
                                target: this.currHitNode,
                            });
                        }, 1000);
                        longPressPointer = this.pointers[0];
                    }
                }
                else {
                    if (!this.keymap["Control"]) {
                        this.currFlow.removeAllFocus();
                        this.currHitGroup = this.getHitGroup(this.pointers[0].screenPosition);
                        this.currHitGroup && (dragDelta = this.currHitGroup.position.subtract(this.pointers[0].realPosition));
                    }
                    else if (this.keymap["Control"] || this.touchControls["CreateGroup"]) {
                        this.groupStartPoint = this.pointers[0].realPosition.clone();
                        this.currGroup = Group.create(this.currFlow, this.groupStartPoint.clone(), {
                            name: "New Group",
                            width: 0,
                            height: 0,
                        });
                    }
                }
            }
            else {
                clearTimeout(longPressTimerId);
                longPressPointer = null;
                this.currHitNode = null;
                this.currHitGroup = null;
                this.currFlow.removeAllFocus();
                this.currFlow.removeFloatingConnector();
            }
        };
        this.canvas.onpointerup = (ev) => {
            if (!this.currFlow)
                return;
            this.removePointer(this.pointers, ev);
            if ((longPressPointer === null || longPressPointer === void 0 ? void 0 : longPressPointer.id) === ev.pointerId) {
                clearTimeout(longPressTimerId);
                longPressPointer = null;
            }
            if (this.pointers.length < 2)
                prevPinchDistance = -1;
            let screenPosition = this.getRelativePosition(ev);
            let realPosition = screenPosition.transform(this.inverseTransform);
            if (this.currHitNode)
                this.handleGrouping(screenPosition);
            this.currHitNode = null;
            this.currHitGroup = null;
            let hitNode = this.getHitNode(screenPosition);
            hitNode === null || hitNode === void 0 ? void 0 : hitNode.onUp(screenPosition.clone(), realPosition.clone());
            if (this.currGroup) {
                this.addCurrAsNewGroup();
            }
            if (this.currFlow.floatingConnector)
                this.handleConnection(hitNode, screenPosition, realPosition);
        };
        this.canvas.onpointerout = (ev) => {
            if (!this.currFlow)
                return;
            this.removePointer(this.pointers, ev);
            if ((longPressPointer === null || longPressPointer === void 0 ? void 0 : longPressPointer.id) === ev.pointerId) {
                clearTimeout(longPressTimerId);
                longPressPointer = null;
            }
            if (this.pointers.length === 0) {
                if (this.currHitNode) {
                    let screenPosition = this.getRelativePosition(ev);
                    this.handleGrouping(screenPosition);
                }
                this.currHitNode = null;
                this.currHitGroup = null;
            }
            if (this.currGroup) {
                this.addCurrAsNewGroup();
            }
            if (this.currFlow.floatingConnector)
                this.currFlow.removeFloatingConnector();
            if (this.prevHitNode) {
                let screenPosition = this.getRelativePosition(ev);
                let realPosition = screenPosition.transform(this.inverseTransform);
                this.prevHitNode.onExit(screenPosition, realPosition);
                this.prevHitNode = null;
            }
        };
        this.canvas.onpointermove = (ev) => {
            var _a;
            if (!this.currFlow)
                return;
            let screenPosition = this.getRelativePosition(ev);
            let realPosition = screenPosition.transform(this.inverseTransform);
            if ((longPressPointer === null || longPressPointer === void 0 ? void 0 : longPressPointer.id) === ev.pointerId) {
                clearTimeout(longPressTimerId);
                longPressPointer = null;
            }
            this.updatePointer(ev.pointerId, screenPosition, realPosition);
            if (this.pointers.length === 2) {
                let currPinchDistance = Vector.Distance(this.pointers[0].screenPosition, this.pointers[1].screenPosition);
                if (prevPinchDistance > 0) {
                    if (currPinchDistance !== prevPinchDistance) {
                        this.handleZoom(currPinchDistance > prevPinchDistance, Vector.Midpoint(this.pointers[0].screenPosition, this.pointers[1].screenPosition), this.pinchScaleDelta);
                    }
                }
                prevPinchDistance = currPinchDistance;
            }
            if (this.currGroup) {
                if (realPosition.x < this.groupStartPoint.x)
                    this.currGroup.position.x = realPosition.x;
                this.currGroup.width = Math.abs(this.groupStartPoint.x - realPosition.x);
                if (realPosition.y < this.groupStartPoint.y)
                    this.currGroup.position.y = realPosition.y;
                this.currGroup.height = Math.abs(this.groupStartPoint.y - realPosition.y);
            }
            if (this.currHitNode) {
                this.currHitNode.onDrag(screenPosition.clone(), realPosition.clone());
                if (this.currHitNode) {
                    if ((!this.currHitNode.currHitUINode || !this.currHitNode.currHitUINode.draggable) &&
                        !this.currHitNode.currHitTerminal &&
                        !this.currFlow.floatingConnector) {
                        this.currHitNode.position = realPosition.add(dragDelta);
                        let hitGroup = this.getHitGroup(screenPosition);
                        if (hitGroup && hitGroup === this.currHitNode.group) {
                            let groupRealPos = hitGroup.position.transform(this._transform);
                            let nodeRealPos = this.currHitNode.position.transform(this._transform);
                            let intersection = intersects(groupRealPos.x, groupRealPos.y, groupRealPos.x + hitGroup.width * this.scale, groupRealPos.y + hitGroup.height * this.scale, nodeRealPos.x, nodeRealPos.y, nodeRealPos.x + this.currHitNode.width * this.scale, nodeRealPos.y + this.currHitNode.ui.height * this.scale);
                            if (intersection === ViewPort.INSIDE) {
                                let nodeIndex = hitGroup.nodes.findIndex((node) => node.id === this.currHitNode.id);
                                hitGroup.nodeDeltas[nodeIndex] = this.currHitNode.position.subtract(hitGroup.position);
                            }
                        }
                    }
                }
            }
            else {
                if (this.currHitGroup) {
                    this.currHitGroup.position = realPosition.add(dragDelta);
                }
                else if (this.pointers.length === 1 && !this.keymap["Control"] && !this.touchControls["CreateGroup"]) {
                    let delta = screenPosition.subtract(prevPanPosition).multiplyInPlace(1 / this.scale);
                    prevPanPosition = screenPosition;
                    this.updateTransform(null, null, delta);
                }
            }
            if (this.currFlow.floatingConnector)
                this.currFlow.floatingConnector.floatingTip = realPosition;
            if (ev.pointerType === "mouse" && !this.currHitNode) {
                let hitNode = this.getHitNode(screenPosition);
                if (hitNode !== this.prevHitNode) {
                    (_a = this.prevHitNode) === null || _a === void 0 ? void 0 : _a.onExit(screenPosition, realPosition);
                    hitNode === null || hitNode === void 0 ? void 0 : hitNode.onEnter(screenPosition, realPosition);
                }
                else {
                    hitNode && !this.currHitNode && hitNode.onOver(screenPosition, realPosition);
                }
                this.prevHitNode = hitNode;
            }
        };
        this.canvas.onclick = (ev) => {
            if (!this.currFlow)
                return;
            let screenPosition = this.getRelativePosition(ev);
            let realPosition = screenPosition.transform(this.inverseTransform);
            let hitNode = this.getHitNode(screenPosition);
            hitNode === null || hitNode === void 0 ? void 0 : hitNode.onClick(screenPosition.clone(), realPosition.clone());
            if (!hitNode) {
                let hitGroup = this.getHitGroup(screenPosition);
                hitGroup === null || hitGroup === void 0 ? void 0 : hitGroup.onClick(screenPosition.clone(), realPosition.clone());
            }
        };
        this.canvas.oncontextmenu = (ev) => {
            if (!this.currFlow)
                return;
            ev.preventDefault();
            let screenPosition = this.getRelativePosition(ev);
            let realPosition = screenPosition.transform(this.inverseTransform);
            let hitNode = this.getHitNode(screenPosition);
            hitNode === null || hitNode === void 0 ? void 0 : hitNode.onContextMenu(screenPosition, realPosition);
            this.call("context-menu", { screenPos: screenPosition, realPos: realPosition, target: hitNode !== null && hitNode !== void 0 ? hitNode : this });
            if (!this.keymap["Control"])
                this.currFlow.removeAllFocus();
            hitNode && (hitNode.focused = true);
        };
        this.canvas.ondblclick = (ev) => {
            if (!this.currFlow)
                return;
            ev.preventDefault();
            let screenPosition = this.getRelativePosition(ev);
            let realPosition = screenPosition.transform(this.inverseTransform);
            let hitNode = this.getHitNode(screenPosition);
            let hitColor = Color.rgbaToString(this.offUIContext.getImageData(screenPosition.x, screenPosition.y, 1, 1).data);
            let currHitUINode = hitNode === null || hitNode === void 0 ? void 0 : hitNode.getHitUINode(hitColor);
            if (!currHitUINode) {
                this.call("dbl-press", { screenPos: screenPosition, realPos: realPosition, target: hitNode !== null && hitNode !== void 0 ? hitNode : this });
            }
        };
        this.canvas.onwheel = (ev) => {
            if (!this.currFlow)
                return;
            let screenPosition = this.getRelativePosition(ev);
            let hitNode = this.getHitNode(screenPosition);
            if (hitNode) {
                let hitColor = Color.rgbaToString(this.offUIContext.getImageData(screenPosition.x, screenPosition.y, 1, 1).data);
                let hitUINode = hitNode.getHitUINode(hitColor);
                if (hitUINode === null || hitUINode === void 0 ? void 0 : hitUINode.zoomable) {
                    hitNode.onWheel(ev.deltaY < 0, screenPosition, screenPosition.transform(this.inverseTransform));
                    return;
                }
            }
            if (!this.disableScale) {
                ev.preventDefault();
                this.handleZoom(ev.deltaY < 0, screenPosition, this.wheelScaleDelta);
            }
        };
    }
    deRegisterEvents() {
        document.onkeydown = null;
        document.onkeyup = null;
        this.canvas.onpointerdown = null;
        this.canvas.onpointerup = null;
        this.canvas.onpointerout = null;
        this.canvas.onpointermove = null;
        this.canvas.onclick = null;
        this.canvas.oncontextmenu = null;
        this.canvas.ondblclick = null;
        this.canvas.onwheel = null;
    }
    addCurrAsNewGroup() {
        let newGroup = this.currGroup;
        this.currGroup = null;
        if (newGroup.width > 10 && newGroup.height > 10) {
            this.currFlow.groups.push(newGroup);
            [...this.currFlow.nodes.values()]
                .filter((node) => !node.group && node.renderState.viewport === ViewPort.INSIDE)
                .forEach((node) => newGroup.add(node));
        }
    }
    setGenericInput() {
        this.genericInput.className = "flow-connect-input";
        this.genericInput.style.visibility = "hidden";
        this.genericInput.style.pointerEvents = "none";
        this.genericInput.style.padding = "0";
        this.genericInput.onblur = () => {
            this.genericInput.style.visibility = "hidden";
            this.genericInput.style.pointerEvents = "none";
            this.genericInput.onchange = null;
        };
        document.body.appendChild(this.genericInput);
    }
    setupAudioContext() {
        return __awaiter(this, void 0, void 0, function* () {
            if (window.__FLOWCONNECT_AUDIO_CTX__) {
                this._audioContext = window.__FLOWCONNECT_AUDIO_CTX__;
            }
            else {
                this._audioContext = new AudioContext();
                window.__FLOWCONNECT_AUDIO_CTX__ = this._audioContext;
            }
            if (!FlowConnect.audioWorkletsRegistered) {
                let workletUtils = generateWorkletUtils();
                let audioWorklets = generateAudioWorklets(workletUtils.CircularBuffer);
                yield this.audioContext.audioWorklet.addModule(workletUtils.CircularBuffer);
                yield Promise.all(Object.keys(audioWorklets).map((key) => this.audioContext.audioWorklet.addModule(audioWorklets[key])));
                FlowConnect.audioWorkletsRegistered = true;
            }
        });
    }
    showGenericInput(position, value, styles, attributes, callback) {
        if (document.activeElement === this.genericInput)
            return;
        Object.assign(this.genericInput.style, styles);
        Object.assign(this.genericInput, attributes);
        Object.assign(this.genericInput.style, {
            left: position.x + this.canvasDimensions.left + "px",
            top: position.y + this.canvasDimensions.top - 3 + "px",
            visibility: "visible",
            pointerEvents: "all",
        });
        this.genericInput.value = value;
        this.genericInput.onchange = () => callback(this.genericInput.value);
        this.genericInput.focus();
    }
    changeParent(newParent) {
        this.registerObservers(newParent);
    }
    updatePointer(id, screenPosition, realPosition) {
        let pointer = this.pointers.find((pntr) => pntr.id === id);
        if (pointer) {
            pointer.screenPosition = screenPosition;
            pointer.realPosition = realPosition;
        }
    }
    handleZoom(zoomIn, origin, scaleDelta) {
        var _a;
        if ((this._transform.a >= this.maxScale && zoomIn) || (this._transform.a <= this.minScale && !zoomIn))
            return;
        this.updateTransform(zoomIn ? scaleDelta : 1 / scaleDelta, origin, null);
        (_a = this.currFlow) === null || _a === void 0 ? void 0 : _a.call("scale", this.scale);
        this.call("scale", this.scale);
    }
    handleGrouping(screenPosition) {
        let hitGroup = this.getHitGroup(screenPosition);
        let intersection;
        if (hitGroup) {
            let groupRealPos = hitGroup.position.transform(this._transform);
            let nodeRealPos = this.currHitNode.position.transform(this._transform);
            intersection = intersects(groupRealPos.x, groupRealPos.y, groupRealPos.x + hitGroup.width * this.scale, groupRealPos.y + hitGroup.height * this.scale, nodeRealPos.x, nodeRealPos.y, nodeRealPos.x + this.currHitNode.width * this.scale, nodeRealPos.y + this.currHitNode.height * this.scale);
        }
        if (this.currHitNode.group && (this.currHitNode.group !== hitGroup || intersection !== ViewPort.INSIDE)) {
            this.currHitNode.group.remove(this.currHitNode);
        }
        if (hitGroup && intersection === ViewPort.INSIDE) {
            hitGroup.add(this.currHitNode);
        }
    }
    handleConnection(hitNode, screenPosition, realPosition) {
        var _a, _b, _c, _d, _e, _f;
        if (!hitNode) {
            this.currFlow.removeFloatingConnector();
            return;
        }
        let hitTerminal = hitNode.getHitTerminal(Color.rgbaToString(this._offUIContext.getImageData(screenPosition.x, screenPosition.y, 1, 1).data), screenPosition.clone(), realPosition.clone());
        if (hitTerminal)
            hitNode.currHitTerminal = hitTerminal;
        if (!hitTerminal) {
            this.currFlow.removeFloatingConnector();
            return;
        }
        let destination = hitNode.currHitTerminal;
        if (!this.currFlow.floatingConnector.canConnect(destination)) {
            this.currFlow.removeFloatingConnector();
            hitNode.currHitTerminal = null;
            destination.node.currHitTerminal = null;
        }
        else {
            if (destination.type === TerminalType.OUT) {
                const terminal = this.currFlow.removeFloatingConnector();
                (_a = hitNode.currHitTerminal) === null || _a === void 0 ? void 0 : _a.onExit(null, null);
                hitNode.currHitTerminal = null;
                (_b = destination.node.currHitTerminal) === null || _b === void 0 ? void 0 : _b.onExit(null, null);
                destination.node.currHitTerminal = null;
                terminal.connect(destination);
            }
            else {
                if (destination.connectors.length > 0) {
                    if (destination.connectors[0].start === this.currFlow.floatingConnector.start) {
                        this.currFlow.removeFloatingConnector();
                        hitNode.currHitTerminal = null;
                        return;
                    }
                    destination.disconnect();
                    const terminal = this.currFlow.removeFloatingConnector();
                    (_c = hitNode.currHitTerminal) === null || _c === void 0 ? void 0 : _c.onExit(null, null);
                    hitNode.currHitTerminal = null;
                    (_d = destination.node.currHitTerminal) === null || _d === void 0 ? void 0 : _d.onExit(null, null);
                    destination.node.currHitTerminal = null;
                    terminal.connect(destination);
                }
                else {
                    const terminal = this.currFlow.removeFloatingConnector();
                    (_e = hitNode.currHitTerminal) === null || _e === void 0 ? void 0 : _e.onExit(null, null);
                    hitNode.currHitTerminal = null;
                    (_f = destination.node.currHitTerminal) === null || _f === void 0 ? void 0 : _f.onExit(null, null);
                    destination.node.currHitTerminal = null;
                    terminal.connect(destination);
                }
            }
        }
    }
    getRelativePosition(ev) {
        return Vector.create(ev.clientX - this.canvasDimensions.left, ev.clientY - this.canvasDimensions.top);
    }
    updateTransform(scale, scaleOrigin, translate) {
        var _a;
        if (scale) {
            let realSpaceOrigin = scaleOrigin.transform(this.inverseTransform);
            this._transform
                .translateSelf(realSpaceOrigin.x, realSpaceOrigin.y)
                .scaleSelf(scale, scale)
                .translateSelf(-realSpaceOrigin.x, -realSpaceOrigin.y);
        }
        if (translate) {
            this._transform.translateSelf(translate.x, translate.y);
        }
        this.inverseTransform = this._transform.inverse();
        this._context.setTransform(this._transform);
        this._offContext.setTransform(this._transform);
        this._offUIContext.setTransform(this._transform);
        this._offGroupContext.setTransform(this._transform);
        (_a = this.currFlow) === null || _a === void 0 ? void 0 : _a.transform();
        this.call("transform", this);
    }
    translateBy(delta) {
        this.updateTransform(null, null, delta);
    }
    scaleBy(scale, scaleOrigin) {
        this.updateTransform(scale, scaleOrigin);
    }
    addPointer(pointerId, position) {
        this.pointers.push({
            id: pointerId,
            screenPosition: position,
            realPosition: position.transform(this.inverseTransform),
        });
    }
    screenToReal(pos) {
        return pos.transform(this.inverseTransform);
    }
    removePointer(pointers, ev) {
        pointers.splice(pointers.findIndex((pointer) => pointer.id === ev.pointerId), 1);
    }
    getHitNode(position) {
        let rgbaString = Color.rgbaToString(this._offContext.getImageData(position.x, position.y, 1, 1).data);
        return this.currFlow.nodeHitColors.get(rgbaString);
    }
    getHitGroup(position) {
        let rgbaString = Color.rgbaToString(this._offGroupContext.getImageData(position.x, position.y, 1, 1).data);
        return this.currFlow.groupHitColors.get(rgbaString);
    }
    clear() {
        this._context.save();
        this._context.setTransform(this.identity);
        this._context.clearRect(0, 0, this.canvasDimensions.width, this.canvasDimensions.height);
        this._context.restore();
        this._offContext.save();
        this._offContext.setTransform(this.identity);
        this._offContext.clearRect(0, 0, this.canvasDimensions.width, this.canvasDimensions.height);
        this._offContext.restore();
        this._offUIContext.save();
        this._offUIContext.setTransform(this.identity);
        this._offUIContext.clearRect(0, 0, this.canvasDimensions.width, this.canvasDimensions.height);
        this._offUIContext.restore();
        this._offGroupContext.save();
        this._offGroupContext.setTransform(this.identity);
        this._offGroupContext.clearRect(0, 0, this.canvasDimensions.width, this.canvasDimensions.height);
        this._offGroupContext.restore();
    }
    startGlobalTime() {
        if (this.startTime < 0) {
            this.startTime = performance.now();
            this._startGlobalTime();
            this.state = FlowConnectState.Running;
            this.call("start", this);
        }
    }
    _startGlobalTime() {
        var _a;
        this.call("tick", this);
        (_a = this.currFlow) === null || _a === void 0 ? void 0 : _a.call("tick");
        this.timerId = window.requestAnimationFrame(this._startGlobalTime.bind(this));
    }
    stopGlobalTime() {
        var _a;
        if (this.isRootFlowStopped()) {
            cancelAnimationFrame(this.timerId);
            this.startTime = -1;
            this.state = FlowConnectState.Stopped;
            this.call("tickreset", this);
            (_a = this.currFlow) === null || _a === void 0 ? void 0 : _a.call("tickreset");
            this.call("stop", this);
        }
    }
    isRootFlowStopped() {
        let root = this.currFlow;
        while (true) {
            if (root.parentFlow)
                root = root.parentFlow;
            else
                return root.state === FlowState.Stopped;
        }
    }
    _render() {
        this.clear();
        this.currGroup && this.currGroup.render();
        this.currFlow.render();
        this.call("render", this);
        this.frameId = window.requestAnimationFrame(this._render.bind(this));
    }
    addFlow(flow) {
        this.flows.push(flow);
        flow.on("start", () => this.startGlobalTime());
        flow.on("stop", () => this.stopGlobalTime());
    }
    createFlow(options) {
        const flow = Flow.create(this, options);
        this.addFlow(flow);
        return flow;
    }
    render(flow) {
        if (flow === this.currFlow)
            return;
        if (this.currFlow) {
            window.cancelAnimationFrame(this.frameId);
        }
        if (!this.rootFlow || !this.rootFlow.existsInFlow(flow)) {
            this.rootFlow = flow;
        }
        this.currFlow = flow;
        this._render();
    }
    top() {
        this.render(this.rootFlow);
    }
    toJson(flow, persist) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                let serializedFlow = yield flow.serialize(persist);
                return JSON.stringify(serializedFlow, null);
            }
            catch (error) {
                Log.error(error);
            }
        });
    }
    fromJson(json, receive) {
        return __awaiter(this, void 0, void 0, function* () {
            let data;
            let flow = null;
            try {
                data = JSON.parse(json);
                flow = yield Flow.deSerialize(this, data, receive);
                this.addFlow(flow);
            }
            catch (error) {
                Log.error(error);
            }
            return flow;
        });
    }
    detach() {
        var _a;
        (_a = this.currFlow) === null || _a === void 0 ? void 0 : _a.stop();
        this.deRegisterObservers();
        this.deRegisterEvents();
        this.deRegisterChangeListeners();
    }
}
FlowConnect.audioWorkletsRegistered = false;
FlowConnect.plugins = {
    node: {
        "core/empty": EmptyNode,
        "core/subflow": SubFlowNode,
        "core/tunnel": TunnelNode,
    },
    ui: {
        "core/container": Container,
        "core/button": Button,
        "core/dial": Dial,
        "core/display": Display,
        "core/envelope": Envelope,
        "core/x-layout": HorizontalLayout,
        "core/image": Image,
        "core/input": Input,
        "core/label": Label,
        "core/radio-group": RadioGroup,
        "core/select": Select,
        "core/2d-slider": Slider2D,
        "core/slider": Slider,
        "core/source": Source,
        "core/stack": Stack,
        "core/toggle": Toggle,
        "core/v-slider": VSlider,
    },
};
export var FlowConnectState;
(function (FlowConnectState) {
    FlowConnectState["Stopped"] = "Stopped";
    FlowConnectState["Running"] = "Running";
})(FlowConnectState || (FlowConnectState = {}));
export * from "./common/index.js";
export * from "./core/index.js";
export * from "./utils/index.js";
export * from "./ui/index.js";
