import { TerminalType } from "../core/terminal.js";
import { clamp, denormalize, normalize } from "../utils/utils.js";
import { UINode } from "./ui-node.js";
import { FlowState } from "../core/flow.js";
import { Constant } from "../resource/constants.js";
export class VSlider extends UINode {
    constructor(node, _options = DefaultVSliderOptions(node)) {
        super();
        this.draggable = true;
    }
    get value() {
        if (this.propName)
            return this.getProp();
        return this._value;
    }
    set value(value) {
        value = clamp(value, this.min, this.max);
        let oldVal = this.value;
        let newVal = value;
        if (this.propName)
            this.setProp(newVal);
        else {
            this._value = newVal;
            this.reflow();
        }
        if (this.node.flow.state !== FlowState.Stopped)
            this.call("change", this, oldVal, newVal);
    }
    created(options) {
        options = Object.assign(Object.assign({}, DefaultVSliderOptions(this.node)), options);
        const { height, width, style = {}, min = 0, max = 100, value = min, input, output } = options;
        this.min = min;
        this.max = max;
        this.style = Object.assign(Object.assign({}, DefaultVSliderStyle(this.node, width)), style);
        this.height = height !== null && height !== void 0 ? height : this.node.style.rowHeight * 5;
        this.width = width !== null && width !== void 0 ? width : this.node.style.rowHeight;
        this._value = this.propName ? this.getProp() : value;
        this._value = clamp(this._value, this.min, this.max);
        if (input) {
            const terminal = this.createTerminal(TerminalType.IN, "number");
            terminal.on("connect", (_, connector) => {
                if (connector.data)
                    this.value = connector.data;
            });
            terminal.on("data", (_, data) => {
                if (data)
                    this.value = data;
            });
        }
        if (output) {
            const terminal = this.createTerminal(TerminalType.IN, "number");
            terminal.on("connect", (_, connector) => (connector.data = this.value));
        }
        this.node.on("process", () => {
            var _a;
            (_a = this.output) === null || _a === void 0 ? void 0 : _a.setData(this.value);
        });
    }
    paint() {
        let context = this.context;
        context.lineWidth = this.style.railWidth;
        context.strokeStyle = this.style.color;
        context.lineCap = "butt";
        let start = Math.max(this.position.y, this.position.y + this.thumbFill - 3);
        if (start !== this.position.y) {
            context.beginPath();
            context.moveTo(this.position.x + this.width / 2, this.position.y);
            context.lineTo(this.position.x + this.width / 2, start);
            context.stroke();
        }
        start = Math.min(this.position.y + 2 * this.style.thumbRadius + this.thumbFill + 3, this.position.y + this.height);
        if (start !== this.position.y + this.height) {
            context.beginPath();
            context.moveTo(this.position.x + this.width / 2, start);
            context.lineTo(this.position.x + this.width / 2, this.position.y + this.height);
            context.stroke();
        }
        context.fillStyle = this.style.thumbColor;
        context.beginPath();
        context.arc(this.position.x + this.width / 2, this.position.y + this.style.thumbRadius + this.thumbFill, this.style.thumbRadius, 0, Constant.TAU);
        context.fill();
    }
    paintLOD1() {
        let context = this.context;
        context.strokeStyle = "#000";
        context.fillStyle = this.style.color;
        context.strokeRect(this.position.x, this.position.y, this.width, this.height);
        context.fillRect(this.position.x, this.position.y, this.width, this.height);
    }
    offPaint() {
        this.offUIContext.fillStyle = this.hitColor.hexValue;
        this.offUIContext.fillRect(this.position.x, this.position.y, this.width, this.height);
    }
    reflow() {
        this.thumbFill = denormalize(1 - normalize(this.value, this.min, this.max), 0, this.height - 2 * this.style.thumbRadius);
        if (this.input) {
            this.input.position.assign(this.node.position.x - this.node.style.terminalStripMargin - this.input.style.radius, this.position.y + this.height / 2);
        }
        if (this.output) {
            this.output.position.assign(this.node.position.x + this.node.width + this.node.style.terminalStripMargin + this.output.style.radius, this.position.y + this.height / 2);
        }
    }
    onPropChange(_oldVal, newVal) {
        var _a;
        this._value = newVal;
        this.reflow();
        (_a = this.output) === null || _a === void 0 ? void 0 : _a.setData(this.value);
    }
    onDrag(event) {
        let x = this.position.x + this.width / 2 - this.style.railWidth / 2;
        this.thumbFill = event.realPos
            .clamp(x, x, this.position.y + this.style.thumbRadius, this.position.y + this.height - this.style.thumbRadius)
            .subtractInPlace(0, this.position.y + this.style.thumbRadius).y;
        this.value = denormalize(1 - normalize(this.thumbFill, 0, this.height - 2 * this.style.thumbRadius), this.min, this.max);
    }
}
const DefaultVSliderStyle = (node, width) => ({
    color: "#444",
    thumbColor: "#000",
    railWidth: 4,
    thumbRadius: typeof width !== "undefined" ? width / 2 : (node.style.rowHeight * 1.5) / 2,
});
const DefaultVSliderOptions = (node) => {
    return {
        min: 0,
        max: 100,
        value: 0,
        width: node.style.rowHeight,
        height: node.style.rowHeight * 5,
    };
};
