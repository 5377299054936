var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Node } from "./node.js";
import { Terminal, TerminalType } from "./terminal.js";
import { Align } from "../common/enums.js";
export class SubFlowNode extends Node {
    constructor(flow, options) {
        super();
        this.subFlow = options.subFlow;
        this.subFlow.parentFlow = flow;
    }
    setupIO() {
        this.subFlow.inputs.forEach((tunnelNode) => {
            const { name, dataType } = tunnelNode.outputs[0];
            this.addTerminal({ type: TerminalType.IN, name, dataType });
        });
        this.subFlow.outputs.forEach((tunnelNode) => {
            const { name, dataType } = tunnelNode.inputs[0];
            this.addTerminal({ type: TerminalType.OUT, name, dataType });
        });
    }
    created() {
        this.subFlow.on("add-input", (_, tunnel) => {
            let proxyTerminal = Terminal.create(this, TerminalType.IN, tunnel.outputs[0].dataType, {
                name: tunnel.outputs[0].name,
            });
            tunnel.proxyTerminal = proxyTerminal;
            this.addTerminal(proxyTerminal);
        });
        this.subFlow.on("add-output", (_, tunnel) => {
            let proxyTerminal = Terminal.create(this, TerminalType.OUT, tunnel.inputs[0].dataType, {
                name: tunnel.inputs[0].name,
            });
            tunnel.proxyTerminal = proxyTerminal;
            this.addTerminal(proxyTerminal);
        });
        this.subFlow.inputs.forEach((inputNode, idx) => (inputNode.proxyTerminal = this.inputs[idx]));
        this.subFlow.outputs.forEach((outputNode, idx) => (outputNode.proxyTerminal = this.outputs[idx]));
        this.addNodeButton(() => this.flow.flowConnect.render(this.subFlow), SubFlowNode.renderOpenButton, Align.Right);
    }
    process() {
        this.subFlow.start();
    }
    static renderOpenButton(context, params, nodeButton) {
        let style = nodeButton.node.style;
        context.strokeStyle = style.expandButtonColor;
        context.beginPath();
        context.moveTo(params.position.x, params.position.y + style.nodeButtonSize / 2);
        context.lineTo(params.position.x, params.position.y + style.nodeButtonSize);
        context.lineTo(params.position.x + style.nodeButtonSize, params.position.y);
        context.lineTo(params.position.x + style.nodeButtonSize, params.position.y + style.nodeButtonSize / 2);
        context.moveTo(params.position.x + style.nodeButtonSize, params.position.y);
        context.lineTo(params.position.x + style.nodeButtonSize / 2, params.position.y);
        context.moveTo(params.position.x, params.position.y + style.nodeButtonSize);
        context.lineTo(params.position.x + style.nodeButtonSize / 2, params.position.y + style.nodeButtonSize);
        context.closePath();
        context.stroke();
    }
    serialize(persist) {
        const _super = Object.create(null, {
            serialize: { get: () => super.serialize }
        });
        return __awaiter(this, void 0, void 0, function* () {
            const serializedNode = yield _super.serialize.call(this, persist);
            const subFlow = yield this.subFlow.serialize(persist);
            return Object.assign(Object.assign({}, serializedNode), { subFlow });
        });
    }
}
