import { TerminalType } from "../core/terminal.js";
import { UINode } from "./ui-node.js";
import { FlowState } from "../core/flow.js";
import { Constant } from "../resource/constants.js";
export class Toggle extends UINode {
    constructor(node, _options = DefaultToggleOptions(node)) {
        super();
        this._checked = false;
    }
    get checked() {
        if (this.propName)
            return this.getProp();
        return this._checked;
    }
    set checked(checked) {
        let oldVal = this.checked;
        let newVal = checked;
        if (this.propName)
            this.setProp(newVal);
        else
            this._checked = newVal;
        if (this.node.flow.state !== FlowState.Stopped)
            this.call("change", this, oldVal, newVal);
    }
    created(options) {
        options = Object.assign(Object.assign({}, DefaultToggleOptions(this.node)), options);
        const { style = {}, height, value, input, output } = options;
        this.style = Object.assign(Object.assign({}, DefaultToggleStyle()), style);
        this._checked = this.propName ? this.getProp() : value;
        this.height = height !== null && height !== void 0 ? height : this.node.style.rowHeight;
        if (!this.style.grow)
            this.width = this.height * 2.5;
        if (input) {
            const terminal = this.createTerminal(TerminalType.IN, "boolean");
            terminal.on("connect", (_, connector) => {
                if (connector.data)
                    this.checked = connector.data;
            });
            terminal.on("data", (_, data) => {
                if (typeof data !== "undefined")
                    this.checked = data;
            });
        }
        if (output) {
            const terminal = this.createTerminal(TerminalType.OUT, "boolean");
            terminal.on("connect", (_, connector) => (connector.data = this.checked));
        }
        this.node.on("process", () => {
            var _a;
            (_a = this.output) === null || _a === void 0 ? void 0 : _a.setData(this.checked);
        });
    }
    paint() {
        let context = this.context;
        context.strokeStyle = this.style.backgroundColor;
        context.lineWidth = this.height * 0.75;
        context.lineCap = "round";
        context.beginPath();
        context.moveTo(this.position.x + this.context.lineWidth / 2, this.position.y + this.height / 2);
        context.lineTo(this.position.x + this.width - this.context.lineWidth / 2, this.position.y + this.height / 2);
        context.stroke();
        context.fillStyle = this.style.color;
        context.beginPath();
        context.arc(this.checked ? this.position.x + this.width - this.height / 2 : this.position.x + this.height / 2, this.position.y + this.height / 2, this.height / 2, 0, Constant.TAU);
        context.fill();
    }
    paintLOD1() {
        let context = this.context;
        context.strokeStyle = this.style.color;
        context.fillStyle = this.style.backgroundColor;
        context.strokeRect(this.position.x, this.position.y, this.width, this.height);
        context.fillRect(this.position.x, this.position.y, this.width, this.height);
    }
    offPaint() {
        this.offUIContext.fillStyle = this.hitColor.hexValue;
        this.offUIContext.fillRect(this.position.x, this.position.y, this.width, this.height);
    }
    reflow() {
        if (this.input) {
            this.input.position.assign(this.node.position.x - this.node.style.terminalStripMargin - this.input.style.radius, this.position.y + this.height / 2);
        }
        if (this.output) {
            this.output.position.assign(this.node.position.x + this.node.width + this.node.style.terminalStripMargin + this.output.style.radius, this.position.y + this.height / 2);
        }
    }
    onPropChange(_oldVal, newVal) {
        var _a;
        this._checked = newVal;
        (_a = this.output) === null || _a === void 0 ? void 0 : _a.setData(this.checked);
    }
    onClick() {
        this.checked = !this.checked;
    }
}
const DefaultToggleOptions = (node) => ({
    value: false,
    height: node.style.rowHeight * 1.5,
});
const DefaultToggleStyle = () => ({
    backgroundColor: "#999",
    color: "#000",
});
