import { TerminalType } from "../core/terminal.js";
import { Vector } from "../core/vector.js";
import { UINode } from "./ui-node.js";
import { Align } from "../common/enums.js";
export class Button extends UINode {
    constructor(_node, _options) {
        super();
    }
    created(options) {
        options = Object.assign(Object.assign({}, DefaultButtonOptions(this.node)), options);
        const { height, style = {}, input, output, text = "Button" } = options;
        this.style = Object.assign(Object.assign({}, DefaultButtonStyle()), style);
        this.height = (height !== null && height !== void 0 ? height : this.node.style.rowHeight) + 2 * this.style.padding;
        if (input) {
            const terminal = this.createTerminal(TerminalType.IN, "event");
            terminal.on("event", () => this.call("click", this));
        }
        if (output) {
            this.createTerminal(TerminalType.OUT, "event");
        }
        this.label = this.node.createUI("core/label", {
            text,
            style: {
                fontSize: this.style.fontSize,
                font: this.style.font,
                align: Align.Center,
                color: this.style.color,
            },
            height,
        });
        this.label.on("click", (_, position) => this.call("click", this, position));
        this.children.push(this.label);
    }
    paint() {
        Object.assign(this.context, {
            fillStyle: this.style.backgroundColor,
            shadowColor: this.style.shadowColor,
            shadowBlur: this.style.shadowBlur,
            shadowOffsetX: this.style.shadowOffset.x,
            shadowOffsetY: this.style.shadowOffset.y,
        });
        this.context.strokeRect(this.position.x, this.position.y, this.width, this.height);
        this.context.fillRect(this.position.x, this.position.y, this.width, this.height);
    }
    paintLOD1() {
        this.context.strokeStyle = "#000";
        this.paint();
        this.context.strokeRect(this.position.x, this.position.y, this.width, this.height);
    }
    offPaint() {
        this.offUIContext.fillStyle = this.hitColor.hexValue;
        this.offUIContext.fillRect(this.position.x, this.position.y, this.width, this.height);
    }
    reflow() {
        this.label.position = this.position;
        this.label.height = this.height;
        this.label.width = this.width;
        if (this.input) {
            this.input.position.assign(this.node.position.x - this.node.style.terminalStripMargin - this.input.style.radius, this.position.y + this.height / 2);
        }
        if (this.output) {
            this.output.position.assign(this.node.position.x + this.node.width + this.node.style.terminalStripMargin + this.output.style.radius, this.position.y + this.height / 2);
        }
    }
    onPropChange() {
    }
}
const DefaultButtonStyle = () => ({
    backgroundColor: "#666",
    padding: 5,
    color: "#fff",
    font: "arial",
    fontSize: "11px",
    shadowColor: "#555",
    shadowBlur: 3,
    shadowOffset: Vector.create(3, 3),
});
const DefaultButtonOptions = (node) => ({
    text: "Button",
    height: node.style.rowHeight,
});
