import { Evaluator } from "./evaluator.js";
export class Lexer {
    constructor() {
        this.result = [];
        this.numberBuffer = [];
        this.letterBuffer = [];
    }
    tokenize(expr) {
        this.result = [];
        this.numberBuffer = [];
        this.letterBuffer = [];
        let index;
        while ((index = expr.indexOf('...')) !== -1) {
            expr = expr.substring(0, index) + expr.charAt(index + 3).toUpperCase() + expr.substring(index + 4);
        }
        expr = this.replaceConstants(expr);
        let chars = expr.replace(/\s+/g, "").split("");
        chars.forEach(char => {
            if (this.isDigit(char)) {
                this.numberBuffer.push(char);
            }
            else if (this.isDecimalPoint(char)) {
                this.numberBuffer.push(char);
            }
            else if (this.isLetter(char)) {
                if (this.numberBuffer.length) {
                    this.processNumberBuffer();
                    this.result.push(new Token(TokenType.Operator, '*'));
                }
                this.letterBuffer.push(char);
            }
            else if (this.isOperator(char)) {
                if (this.numberBuffer.length)
                    this.processNumberBuffer();
                else if (this.letterBuffer.length)
                    this.processLetterBuffer();
                this.result.push(new Token(TokenType.Operator, char));
            }
            else if (this.isLeftParenthesis(char)) {
                if (this.letterBuffer.length) {
                    this.result.push(new Token(TokenType.Function, this.letterBuffer.join('')));
                    this.letterBuffer = [];
                }
                else if (this.numberBuffer.length) {
                    this.processNumberBuffer();
                    this.result.push(new Token(TokenType.Operator, '*'));
                }
                this.result.push(new Token(TokenType.LParenthesis, char));
            }
            else if (this.isRightParenthesis(char)) {
                if (this.letterBuffer.length)
                    this.processLetterBuffer();
                if (this.numberBuffer.length)
                    this.processNumberBuffer();
                this.result.push(new Token(TokenType.RParenthesis, char));
            }
            else if (this.isComma(char)) {
                if (this.numberBuffer.length)
                    this.processNumberBuffer();
                if (this.letterBuffer.length)
                    this.processLetterBuffer();
                this.result.push(new Token(TokenType.ArgSeperator, char));
            }
            else
                throw Error('Unknown character: ' + char);
        });
        if (this.numberBuffer.length)
            this.processNumberBuffer();
        if (this.letterBuffer.length)
            this.processLetterBuffer();
        return this.result;
    }
    replaceConstants(expr) {
        return expr
            .replace(/pi/g, Evaluator.constants.pi.toString())
            .replace(/tau/g, Evaluator.constants.tau.toString())
            .replace(/phi/g, Evaluator.constants.phi.toString())
            .replace(/ln2/g, Evaluator.constants.ln2.toString())
            .replace(/ln10/g, Evaluator.constants.ln10.toString())
            .replace(/log2e/g, Evaluator.constants.log2e.toString())
            .replace(/log10e/g, Evaluator.constants.log10e.toString());
    }
    processNumberBuffer() {
        let literal = parseFloat(this.numberBuffer.join(''));
        this.numberBuffer = [];
        if (!isNaN(literal)) {
            this.result.push(new Token(TokenType.Literal, literal));
        }
    }
    processLetterBuffer() {
        for (let i = 0; i < this.letterBuffer.length; i++) {
            this.result.push(new Token(TokenType.Variable, this.letterBuffer[i]));
            if (i < this.letterBuffer.length - 1)
                this.result.push(new Token(TokenType.Operator, '*'));
        }
        this.letterBuffer = [];
    }
    isComma(char) { return (char === ","); }
    isDigit(char) { return /\d/.test(char); }
    isLetter(char) { return /[a-zA-Z]/.test(char); }
    isOperator(char) { return Lexer.operators.includes(char); }
    isLeftParenthesis(char) { return char === "("; }
    isRightParenthesis(char) { return char == ")"; }
    isDecimalPoint(char) { return char === '.'; }
}
Lexer.operators = ['+', '-', '*', '/', '^', '%', '&', '|'];
export var TokenType;
(function (TokenType) {
    TokenType["Literal"] = "Literal";
    TokenType["Variable"] = "Variable";
    TokenType["Operator"] = "Operator";
    TokenType["LParenthesis"] = "LParenthesis";
    TokenType["RParenthesis"] = "RParenthesis";
    TokenType["Function"] = "Function";
    TokenType["ArgSeperator"] = "ArgSeperator";
})(TokenType || (TokenType = {}));
export class Token {
    constructor(type, value) {
        this.type = type;
        this.value = value;
        this.count = 0;
    }
    get precedence() { return Token.precedence[this.value]; }
    get associativity() { return Token.associativity[this.value]; }
    toString() { return this.type + '(' + this.value + ')'; }
}
Token.associativity = {
    '^': 'right',
    '*': 'left',
    '/': 'left',
    '+': 'left',
    '-': 'left',
    '%': 'left',
    '&': 'left',
    '|': 'left'
};
Token.precedence = {
    '|': 0,
    '&': 1,
    '+': 2,
    '-': 2,
    '*': 3,
    '/': 3,
    '%': 3,
    '^': 4
};
