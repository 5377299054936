import { Color } from "../core/color.js";
import { Hooks } from "../core/hooks.js";
import { NodeState } from "../core/node.js";
import { Terminal, TerminalType } from "../core/terminal.js";
import { Vector } from "../core/vector.js";
import { LOD, ViewPort } from "../common/enums.js";
import { uuid, intersects, capitalize } from "../utils/utils.js";
import { FlowConnect, Log } from "../flow-connect.js";
export class UINode extends Hooks {
    constructor() {
        super();
        this.width = 0;
        this.height = 0;
        this.children = [];
        this.disabled = false;
    }
    get context() {
        return this.node.context;
    }
    get offUIContext() {
        return this.node.offUIContext;
    }
    get disabled() {
        return this._disabled;
    }
    set disabled(disabled) {
        this._disabled = disabled;
        this.children.forEach((child) => (child.disabled = disabled));
    }
    get visible() {
        return this._visible;
    }
    set visible(value) {
        this._visible = value;
        this.node.ui.update();
    }
    static create(type, node, options = DefaultUINodeOptions(node)) {
        options.style = Object.assign(Object.assign({}, (node.flow.flowConnect.getDefaultStyle("ui", type) || {})), (options.style || {}));
        const construct = FlowConnect.getRegistered("ui", type);
        const uiNode = new construct(node, options);
        const { hitColor, id = uuid(), visible = true, propName, position = Vector.Zero() } = options;
        uiNode.type = type;
        uiNode.node = node;
        uiNode.setHitColor(hitColor);
        uiNode.id = id;
        uiNode._visible = visible;
        uiNode.position = position;
        uiNode.propName = propName;
        if (propName) {
            node.watch(propName, (oldVal, newVal) => uiNode.onPropChange(oldVal, newVal));
        }
        uiNode.created(options);
        return uiNode;
    }
    createTerminal(type, dataType, name) {
        if ((type === TerminalType.IN && this.input) || (type === TerminalType.OUT && this.output)) {
            Log.error("Terminal for UINode was already configured, ignoring terminal creation");
            return;
        }
        const terminal = Terminal.create(this.node, type, dataType, { name: name !== null && name !== void 0 ? name : "", ui: true });
        if (type === TerminalType.IN) {
            this.input = terminal;
            this.node.inputsUI.push(terminal);
            terminal.on("connect", () => (this.disabled = true));
            terminal.on("disconnect", () => (this.disabled = false));
        }
        else {
            this.output = terminal;
            this.node.outputsUI.push(terminal);
        }
        return terminal;
    }
    append(childs) {
        if (Array.isArray(childs))
            this.children.push(...childs);
        else
            this.children.push(childs);
        this.update();
        this.node.ui.update();
    }
    update() {
        this.reflow();
        this.call("update", this);
        this.children.forEach((child) => child.update());
    }
    updateRenderState() {
        if (this.node.renderState.nodeState === NodeState.MINIMIZED)
            return;
        let realPos = this.position.transform(this.node.flow.flowConnect.transform);
        this.renderState = intersects(0, 0, this.node.flow.flowConnect.canvasDimensions.width, this.node.flow.flowConnect.canvasDimensions.height, realPos.x, realPos.y, realPos.x + this.width * this.node.flow.flowConnect.scale, realPos.y + this.height * this.node.flow.flowConnect.scale);
        this.children.forEach((child) => child.updateRenderState());
    }
    setHitColor(hitColor) {
        if (!hitColor) {
            hitColor = Color.Random();
            while (this.node.uiNodes.get(hitColor.rgbaString) || this.node.terminals.get(hitColor.rgbaString))
                hitColor = Color.Random();
        }
        this.hitColor = hitColor;
        this.node.uiNodes.set(this.hitColor.rgbaString, this);
    }
    render() {
        if (!this.visible)
            return;
        if (this.renderState === ViewPort.OUTSIDE)
            return;
        let context = this.context;
        if (this.node.renderState.lod === LOD.LOD1) {
            context.save();
            this.paintLOD1();
            context.restore();
        }
        else if (this.node.renderState.lod === LOD.LOD2) {
            context.save();
            this.paint();
            context.restore();
            this.offUIContext.save();
            this.offPaint();
            this.offUIContext.restore();
        }
        else {
            if (this.type === "core/container") {
                context.save();
                this.paintLOD1();
                context.restore();
            }
        }
        if (this.node.renderState.lod > 0) {
            if (this.input)
                this.input.render();
            if (this.output)
                this.output.render();
        }
        this.call("render", this);
        this.children.forEach((child) => child.render());
    }
    getProp() {
        return this.node.state[this.propName];
    }
    setProp(propValue) {
        this.node.state[this.propName] = propValue;
    }
    query(query) {
        let result = [];
        query = query.trim();
        let queue = [this];
        while (queue.length !== 0) {
            let curr = queue.shift();
            if (curr.type === query)
                result.push(curr);
            curr.children.forEach((child) => queue.push(child));
        }
        return result;
    }
    sendEvent(type, event) {
        if (this.disabled)
            return;
        const orgType = type;
        type = `on${type
            .split("-")
            .map((part) => capitalize(part))
            .reduce((prev, curr) => prev + curr, "")}`;
        this[type](event);
        this.call(orgType, event);
    }
    onWheel(_) { }
    onEnter(_disabled) { }
    onExit(_disabled) { }
    onOver(_) { }
    onDown(_) { }
    onUp(_) { }
    onClick(_) { }
    onDrag(_) { }
    onContextMenu(_) { }
}
const DefaultUINodeOptions = (node) => {
    return {
        visible: true,
        style: {},
        propName: null,
        id: uuid(),
        hitColor: null,
        height: node.style.rowHeight,
        position: Vector.Zero(),
    };
};
