export var ViewPort;
(function (ViewPort) {
    ViewPort["INSIDE"] = "INSIDE";
    ViewPort["OUTSIDE"] = "OUTSIDE";
    ViewPort["INTERSECT"] = "INTERSECT";
})(ViewPort || (ViewPort = {}));
export var LOD;
(function (LOD) {
    LOD[LOD["LOD0"] = 0] = "LOD0";
    LOD[LOD["LOD1"] = 1] = "LOD1";
    LOD[LOD["LOD2"] = 2] = "LOD2";
})(LOD || (LOD = {}));
export var Align;
(function (Align) {
    Align[Align["Left"] = 0] = "Left";
    Align[Align["Center"] = 1] = "Center";
    Align[Align["Right"] = 2] = "Right";
})(Align || (Align = {}));
export var GlobalEventType;
(function (GlobalEventType) {
    GlobalEventType[GlobalEventType["Emitter"] = 0] = "Emitter";
    GlobalEventType[GlobalEventType["Receiver"] = 1] = "Receiver";
})(GlobalEventType || (GlobalEventType = {}));
