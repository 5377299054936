import { UINode } from "./ui-node.js";
export class Stack extends UINode {
    constructor(node, _options = DefaultStackOptions(node)) {
        super();
    }
    created(options) {
        options = Object.assign(Object.assign({}, DefaultStackOptions(this.node)), options);
        const { height, style = {}, childs = [] } = options;
        this.style = Object.assign(Object.assign({}, DefaultStackStyle()), style);
        this.height = height !== null && height !== void 0 ? height : this.node.style.rowHeight;
        if (childs)
            this.children.push(...childs);
    }
    paint() { }
    paintLOD1() { }
    offPaint() {
        this.offUIContext.fillStyle = this.hitColor.hexValue;
        this.offUIContext.fillRect(this.position.x, this.position.y, this.width, this.height);
    }
    reflow() {
        let children = this.children.filter((child) => child.visible);
        let actualTotalHeight = children.reduce((acc, curr) => acc + curr.height, 0);
        let effectiveSpacing = (children.length - 1) * this.style.spacing;
        this.height = actualTotalHeight + effectiveSpacing;
        let y = this.position.y;
        children.forEach((child) => {
            child.position.assign(this.position.x, y);
            child.width = this.width;
            y += child.height + this.style.spacing;
        });
    }
    onPropChange() { }
}
const DefaultStackStyle = () => ({
    spacing: 0,
});
const DefaultStackOptions = (node) => ({
    height: node.style.rowHeight,
});
