import { TerminalType } from "../core/terminal.js";
import { UINode } from "./ui-node.js";
import { binarySearch, exists } from "../utils/utils.js";
import { FlowState } from "../core/flow.js";
import { Align } from "../common/enums.js";
export class Label extends UINode {
    constructor(_node, options) {
        super();
        options = Object.assign(Object.assign({}, DefaultLabelOptions()), options);
        const { style = {} } = options;
        this.style = Object.assign(Object.assign({}, DefaultLabelStyle()), style);
    }
    get text() {
        let value;
        if (this.propName)
            value = this.getProp();
        else
            value = this._text;
        if (typeof value === "number")
            value = this.format(value);
        return value;
    }
    set text(text) {
        let oldVal = this._text;
        let newVal = text;
        if (this.propName) {
            this.setProp(newVal);
        }
        else {
            this._text = newVal;
            this.reflow();
        }
        if (this.node.flow.state !== FlowState.Stopped)
            this.call("change", this, oldVal, newVal);
    }
    created(options) {
        const { text = "Label", input, output, height } = options;
        if (input) {
            const terminal = this.createTerminal(TerminalType.IN, "any");
            terminal.on("connect", (_, connector) => {
                if (connector.data)
                    this.text = connector.data;
            });
            terminal.on("data", (_, data) => {
                if (data)
                    this.text = data;
            });
        }
        if (output) {
            const terminal = this.createTerminal(TerminalType.OUT, "string");
            terminal.on("connect", (_, connector) => (connector.data = this.text));
        }
        this._text = this.propName ? this.getProp() : text;
        this.reflow();
        this.height = height !== null && height !== void 0 ? height : this.textHeight + 5;
        if (!this.style.grow)
            this.width = this.orgTextWidth;
        this.node.on("process", () => { var _a; return (_a = this.output) === null || _a === void 0 ? void 0 : _a.setData(this.text); });
    }
    paint() {
        let context = this.context;
        context.fillStyle = this.style.backgroundColor;
        context.fillRect(this.position.x, this.position.y, this.width, this.height);
        context.fillStyle = this.style.color;
        context.font = this.style.fontSize + " " + this.style.font;
        context.textBaseline = "middle";
        let y = this.position.y + this.height / 2;
        let x = this.position.x;
        if (this.style.align === Align.Center) {
            x += this.width / 2 - this.textWidth / 2;
        }
        else if (this.style.align === Align.Right) {
            x += this.width - this.textWidth - this.style.padding;
        }
        else {
            x += this.style.padding;
        }
        context.fillText(this.displayText, x, y);
    }
    paintLOD1() {
        let context = this.context;
        context.strokeStyle = "#000";
        context.fillStyle = this.style.color;
        context.strokeRect(this.position.x, this.position.y, this.width, this.height);
        context.fillRect(this.position.x, this.position.y, this.width, this.height);
    }
    offPaint() {
        this.offUIContext.fillStyle = this.hitColor.hexValue;
        this.offUIContext.fillRect(this.position.x, this.position.y, this.width, this.height);
    }
    reflow() {
        let context = this.context;
        context.font = this.style.fontSize + " " + this.style.font;
        this.orgTextWidth = context.measureText(this.text).width;
        this.displayText = this.getBestFitString();
        let metrics = context.measureText(this.displayText);
        context.font = null;
        this.textWidth = metrics.width;
        this.textHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent + 5;
        if (this.input) {
            this.input.position.assign(this.node.position.x - this.node.style.terminalStripMargin - this.input.style.radius, this.position.y + this.height / 2);
        }
        if (this.output) {
            this.output.position.assign(this.node.position.x + this.node.width + this.node.style.terminalStripMargin + this.output.style.radius, this.position.y + this.height / 2);
        }
    }
    getBestFitString() {
        let text = this.text;
        if (typeof text !== "string")
            text = text.toString();
        let width = this.context.measureText(text).width;
        const ellipsis = "…";
        const ellipsisWidth = this.context.measureText(ellipsis).width;
        if (width <= this.width || width <= ellipsisWidth)
            return text;
        const index = binarySearch({
            max: text.length,
            getValue: (idx) => this.context.measureText(text.substring(0, idx)).width,
            match: this.width - ellipsisWidth,
        });
        return text.substring(0, index) + ellipsis;
    }
    format(value) {
        return exists(this.style.precision) ? value.toFixed(this.style.precision) : value.toString();
    }
    onPropChange(_oldVal, newVal) {
        this._text = newVal;
        this.reflow();
        this.output && this.output.setData(this._text);
    }
}
let DefaultLabelStyle = () => ({
    color: "#000",
    backgroundColor: "transparent",
    fontSize: "11px",
    font: "arial",
    align: Align.Left,
    padding: 0,
});
let DefaultLabelOptions = () => ({
    text: "Label",
});
