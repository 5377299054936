var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Vector } from "./vector.js";
import { ViewPort, LOD, Align } from "../common/enums.js";
import { UINode } from "../ui/ui-node.js";
import { Container } from "../ui/container.js";
import { uuid, intersects } from "../utils/utils.js";
import { Color } from "./color.js";
import { FlowState } from "./flow.js";
import { Terminal, TerminalType } from "./terminal.js";
import { Hooks } from "./hooks.js";
import { Log } from "../utils/logger.js";
import { FlowConnect } from "../flow-connect.js";
export class Node extends Hooks {
    constructor() {
        super();
        this.renderers = {};
        this.focused = false;
        this.inputs = [];
        this.outputs = [];
        this.inputsUI = [];
        this.outputsUI = [];
        this.group = null;
        this.renderState = { viewport: ViewPort.INSIDE, nodeState: NodeState.MAXIMIZED, lod: LOD.LOD2 };
        this._zIndex = 0;
        this.stateObserver = new Hooks();
        this.uiNodes = new Map();
        this.terminals = new Map();
        this.nodeButtons = new Map();
    }
    get style() {
        return this._style;
    }
    set style(style) {
        this._style = Object.assign(Object.assign({}, this._style), style);
    }
    get height() {
        return this.ui.height;
    }
    get context() {
        return this.flow.flowConnect.context;
    }
    get offContext() {
        return this.flow.flowConnect.offContext;
    }
    get offUIContext() {
        return this.flow.flowConnect.offUIContext;
    }
    get position() {
        return this._position;
    }
    set position(position) {
        this._position = position;
        this.reflow();
        this.ui.update();
        this.updateRenderState();
    }
    get zIndex() {
        return this._zIndex;
    }
    set zIndex(zIndex) {
        if (this.flow.sortedNodes.remove(this)) {
            this._zIndex = zIndex;
            this.flow.sortedNodes.add(this);
        }
        else {
            this._zIndex = zIndex;
        }
    }
    get width() {
        return this._width;
    }
    set width(width) {
        this._width = width;
        this.ui.width = width;
        this.ui.update();
    }
    static create(type, flow, position, options = DefaultNodeOptions(), isDeserialized = false) {
        const construct = FlowConnect.getRegistered("node", type);
        const node = new construct(flow, options);
        const { name = "New Node", width = 100, style = {}, id = uuid(), state = {}, hitColor, inputs, outputs } = options;
        node.flow = flow;
        node.type = type;
        node.name = name;
        node._width = width;
        node.style = Object.assign(Object.assign(Object.assign({}, DefaultNodeStyle()), (flow.flowConnect.getDefaultStyle("node", type) || {})), style);
        node.id = id;
        node.state = state;
        node._position = position;
        node.ui = node.createUI("core/container", { width: node.width });
        node.setHitColor(hitColor);
        node.setupTerminals(inputs, outputs);
        node.reflow();
        node.ui.update();
        node.addNodeButton((n) => n.toggle(), Node.renderControlButton, Align.Left);
        node.on("transform", (n) => n.updateRenderState());
        !isDeserialized && node.setupIO(options);
        node.created(options);
        node.setupState(node.state);
        node.ui.update();
        node.reflow();
        return node;
    }
    setupTerminals(inputs, outputs) {
        inputs &&
            this.inputs.push(...inputs.map((input) => Terminal.create(this, TerminalType.IN, input.dataType, {
                name: input.name,
                id: input.id ? input.id : null,
                hitColor: input.hitColor ? Color.create(input.hitColor) : null,
            })));
        outputs &&
            this.outputs.push(...outputs.map((output) => Terminal.create(this, TerminalType.OUT, output.dataType, {
                name: output.name,
                id: output.id ? output.id : null,
                hitColor: output.hitColor ? Color.create(output.hitColor) : null,
            })));
    }
    setupState(state) {
        this.state = new Proxy({}, {
            set: (target, prop, value) => {
                let oldValue = target[prop];
                target[prop] = value;
                this.stateObserver.call(prop, oldValue, value);
                return true;
            },
        });
        Object.keys(state).forEach((key) => (this.state[key] = state[key]));
    }
    watch(propName, callback) {
        if (typeof this.state[propName] !== "undefined") {
            return this.stateObserver.on(propName, callback);
        }
        else {
            Log.error(`Cannot watch prop '${propName}', prop not found`);
        }
    }
    unwatch(propName, id) {
        if (typeof this.state[propName] !== "undefined") {
            this.stateObserver.off(propName, id);
        }
        else {
            Log.error(`Cannot unwatch prop '${propName}', prop not found`);
        }
    }
    setHitColor(hitColor) {
        if (!hitColor) {
            hitColor = Color.Random();
            while (this.flow.nodeHitColors.get(hitColor.rgbaString))
                hitColor = Color.Random();
        }
        this.hitColor = hitColor;
        this.flow.nodeHitColors.set(this.hitColor.rgbaString, this);
    }
    addNodeButton(onClick, render, align) {
        let newNodeButton = new NodeButton(this, onClick, render, align);
        let noOfButtons = [...this.nodeButtons.values()].filter((nodeButton) => nodeButton.align === newNodeButton.align).length - 1;
        let deltaX;
        if (align === Align.Left)
            deltaX = noOfButtons * (this.style.nodeButtonSize + this.style.nodeButtonSpacing);
        else
            deltaX =
                this.width -
                    noOfButtons * (this.style.nodeButtonSize + this.style.nodeButtonSpacing) -
                    this.style.nodeButtonSize;
        newNodeButton.deltaX = deltaX;
        return newNodeButton;
    }
    reflow() {
        let y = this.position.y + this.style.terminalRowHeight / 2 + this.style.padding / 2 + this.style.titleHeight;
        if (this.inputs.length > this.outputs.length) {
            this.recalculateInputTerminals(y);
            y =
                this.position.y +
                    (this.inputs.length * this.style.terminalRowHeight) / 2 -
                    (this.outputs.length * this.style.terminalRowHeight) / 2 +
                    this.style.terminalRowHeight / 2 +
                    this.style.padding / 2 +
                    this.style.titleHeight;
            this.recalculateOutputTerminals(y);
        }
        else {
            this.recalculateOutputTerminals(y);
            y =
                this.position.y +
                    (this.outputs.length * this.style.terminalRowHeight) / 2 -
                    (this.inputs.length * this.style.terminalRowHeight) / 2 +
                    this.style.terminalRowHeight / 2 +
                    this.style.padding / 2 +
                    this.style.titleHeight;
            this.recalculateInputTerminals(y);
        }
    }
    updateRenderState() {
        let realPos = this.position.transform(this.flow.flowConnect.transform);
        this.renderState.viewport = intersects(0, 0, this.flow.flowConnect.canvasDimensions.width, this.flow.flowConnect.canvasDimensions.height, realPos.x, realPos.y, realPos.x + this.width * this.flow.flowConnect.scale, realPos.y +
            (this.renderState.nodeState === NodeState.MAXIMIZED ? this.ui.height : this.style.titleHeight) *
                this.flow.flowConnect.scale);
        if (this.flow.flowConnect.scale > 0.6)
            this.renderState.lod = LOD.LOD2;
        else if (this.flow.flowConnect.scale <= 0.6 && this.flow.flowConnect.scale > 0.3)
            this.renderState.lod = LOD.LOD1;
        else
            this.renderState.lod = LOD.LOD0;
        if (this.renderState.viewport === ViewPort.INTERSECT) {
            this.ui.updateRenderState();
        }
    }
    recalculateInputTerminals(y) {
        this.inputs.forEach((terminal) => {
            terminal.position.x = this.position.x - this.style.terminalStripMargin - terminal.style.radius;
            terminal.position.y = y;
            y += this.style.terminalRowHeight;
        });
    }
    recalculateOutputTerminals(y) {
        this.outputs.forEach((terminal) => {
            terminal.position.x = this.position.x + this.ui.width + this.style.terminalStripMargin + terminal.style.radius;
            terminal.position.y = y;
            y += this.style.terminalRowHeight;
        });
    }
    getHitTerminal(hitColor, screenPosition, realPosition) {
        let hitTerminal = null;
        realPosition = realPosition.transform(this.flow.flowConnect.transform);
        let thisRealPosition = this.position.transform(this.flow.flowConnect.transform);
        if ((this.inputs.length + this.inputsUI.length > 0 && realPosition.x < thisRealPosition.x) ||
            (this.outputs.length + this.outputsUI.length > 0 &&
                realPosition.x > thisRealPosition.x + this.ui.width * this.flow.flowConnect.scale)) {
            hitTerminal = this.terminals.get(hitColor);
        }
        if (this.currHitTerminal && this.currHitTerminal !== hitTerminal) {
            this.currHitTerminal.onExit(screenPosition, realPosition);
            hitTerminal === null || hitTerminal === void 0 ? void 0 : hitTerminal.onEnter(screenPosition, realPosition);
        }
        return hitTerminal;
    }
    getHitUINode(hitColor) {
        let uiNode = this.uiNodes.get(hitColor);
        if (uiNode instanceof Container)
            return null;
        return uiNode;
    }
    getHitNodeButton(hitColor) {
        return this.nodeButtons.get(hitColor);
    }
    run() {
        if (this.flow.state === FlowState.Stopped)
            return;
        const inputs = this.inputs.map((terminal) => (terminal.connectors.length > 0 ? terminal.connectors[0].data : null));
        this.process(inputs);
        this.call("process", this, inputs);
    }
    render() {
        if (this.renderState.viewport === ViewPort.OUTSIDE)
            return;
        if (this.renderState.nodeState === NodeState.MAXIMIZED)
            this.ui.render();
        let context = this.context;
        context.save();
        this.renderTerminals(context);
        this.renderName(context);
        this.renderFocused(context);
        let scopeFlowConnect = this.flow.flowConnect.getRegisteredRenderer("node");
        let scopeFlow = this.flow.renderers.node;
        let scopeNode = this.renderers.node;
        const renderFn = (scopeNode && scopeNode(this)) ||
            (scopeFlow && scopeFlow(this)) ||
            (scopeFlowConnect && scopeFlowConnect(this)) ||
            this._render;
        renderFn(context, this.getRenderParams(), this);
        context.restore();
        this.nodeButtons.forEach((nodeButton) => nodeButton.render());
        this.offContext.save();
        this._offRender();
        this.offContext.restore();
        this.call("render", this);
    }
    renderTerminals(context) {
        if (this.renderState.nodeState === NodeState.MAXIMIZED) {
            if (this.renderState.lod > 0) {
                this.inputs.forEach((terminal) => terminal.render());
                this.outputs.forEach((terminal) => terminal.render());
            }
            context.fillStyle = this.style.color;
            context.font = this.style.fontSize + " " + this.style.font;
            context.textBaseline = "middle";
            this.inputs.forEach((terminal) => {
                context.fillText(terminal.name, terminal.position.x + terminal.style.radius + this.style.terminalStripMargin + this.style.padding, terminal.position.y);
            });
            this.outputs.forEach((terminal) => {
                context.fillText(terminal.name, terminal.position.x -
                    terminal.style.radius -
                    this.style.terminalStripMargin -
                    this.style.padding -
                    context.measureText(terminal.name).width, terminal.position.y);
            });
        }
        else {
            context.fillStyle = this.style.minimizedTerminalColor;
            if (this.inputs.length + this.inputsUI.length > 0) {
                let radius = this.inputs.length > 0 ? this.inputs[0].style.radius : this.inputsUI[0].style.radius;
                context.fillRect(this.position.x - this.style.terminalStripMargin - radius * 2, this.position.y + this.style.titleHeight / 2 - radius, radius * 2, radius * 2);
            }
            if (this.outputs.length + this.outputsUI.length > 0) {
                let radius = this.outputs.length > 0 ? this.outputs[0].style.radius : this.outputsUI[0].style.radius;
                context.fillRect(this.position.x + this.width + this.style.terminalStripMargin, this.position.y + this.style.titleHeight / 2 - radius, radius * 2, radius * 2);
            }
        }
    }
    renderName(context) {
        context.fillStyle = this.style.titleColor;
        context.font = this.style.titleFontSize + " " + this.style.titleFont;
        context.textBaseline = "middle";
        context.fillText(this.name, this.position.x + this.ui.width / 2 - context.measureText(this.name).width / 2, this.position.y + this.style.titleHeight / 2);
    }
    renderFocused(context) {
        if (this.focused) {
            context.strokeStyle = this.style.outlineColor;
            context.lineWidth = 2;
            let inputTerminalsWidth;
            if (this.inputs.length === 0) {
                inputTerminalsWidth = this.inputsUI.length === 0 ? 0 : this.inputsUI[0].style.radius * 2;
            }
            else {
                inputTerminalsWidth = this.inputs[0].style.radius * 2;
            }
            inputTerminalsWidth += this.style.terminalStripMargin * 2;
            let outputTerminalsWidth;
            if (this.outputs.length === 0) {
                outputTerminalsWidth = this.outputsUI.length === 0 ? 0 : this.outputsUI[0].style.radius * 2;
            }
            else {
                outputTerminalsWidth = this.outputs[0].style.radius * 2;
            }
            outputTerminalsWidth += this.style.terminalStripMargin * 2;
            context.strokeRoundRect(this.position.x - inputTerminalsWidth, this.position.y, this.width + inputTerminalsWidth + outputTerminalsWidth, this.renderState.nodeState === NodeState.MAXIMIZED
                ? this.ui.height + this.style.padding
                : this.style.titleHeight, 4);
        }
    }
    _render() {
    }
    _offRender() {
        this.offContext.fillStyle = this.hitColor.rgbaCSSString;
        let x = this.position.x;
        let y = this.position.y;
        let inputTerminalsStripWidth = 0, outputTerminalsStripWidth = 0;
        if (this.inputs.length + this.inputsUI.length !== 0) {
            let radius = this.inputs.length > 0 ? this.inputs[0].style.radius : this.inputsUI[0].style.radius;
            x -= this.style.terminalStripMargin + radius * 2;
            inputTerminalsStripWidth = radius * 2 + this.style.terminalStripMargin;
        }
        if (this.outputs.length + this.outputsUI.length !== 0) {
            let radius = this.outputs.length > 0 ? this.outputs[0].style.radius : this.outputsUI[0].style.radius;
            outputTerminalsStripWidth = radius * 2 + this.style.terminalStripMargin;
        }
        this.offContext.fillRect(x, y, this.ui.width + inputTerminalsStripWidth + outputTerminalsStripWidth, this.renderState.nodeState === NodeState.MAXIMIZED ? this.ui.height : this.style.titleHeight);
    }
    static renderControlButton(context, params, nodeButton) {
        let style = nodeButton.node.style;
        context.fillStyle = style.maximizeButtonColor;
        context.fillRect(params.position.x, params.position.y, style.nodeButtonSize, style.nodeButtonSize);
    }
    getRenderParams() {
        return {
            position: this.position.serialize(),
            width: this.width,
            height: this.ui.height,
            focus: this.focused,
        };
    }
    addTerminals(terminals) {
        terminals === null || terminals === void 0 ? void 0 : terminals.forEach((terminal) => this.addTerminal(terminal));
    }
    addTerminal(terminal) {
        let t = null;
        if (!(terminal instanceof Terminal)) {
            t = Terminal.create(this, terminal.type, terminal.dataType, {
                name: terminal.name,
                propName: terminal.propName,
                style: terminal.style,
                id: terminal.id,
                hitColor: terminal.hitColor ? Color.create(terminal.hitColor) : null,
            });
        }
        else {
            t = terminal;
        }
        (terminal.type === TerminalType.IN ? this.inputs : this.outputs).push(t);
        this.ui.update();
        this.reflow();
        return t;
    }
    removeTerminal(terminal) {
        let type = terminal.type;
        let index = type === TerminalType.IN ? this.inputs.indexOf(terminal) : this.outputs.indexOf(terminal);
        if (index < 0) {
            Log.error("Cannot remove terminal, terminal not found");
            return;
        }
        terminal.disconnect();
        if (type === TerminalType.IN)
            this.inputs.splice(index, 1);
        else
            this.outputs.splice(index, 1);
        terminal.offAll();
        this.ui.update();
        this.reflow();
    }
    getInput(terminal) {
        if (typeof terminal === "string") {
            let inputTerminal = this.inputs.find((currTerm) => currTerm.name === terminal);
            if (inputTerminal)
                return inputTerminal.getData();
        }
        else {
            if (this.inputs[terminal])
                return this.inputs[terminal].getData();
        }
        return null;
    }
    getInputs() {
        return this.inputs.map((terminal) => terminal.getData());
    }
    setOutputs(outputs, data) {
        if (typeof outputs === "string") {
            let outputTerminal = this.outputs.find((term) => term.name === outputs);
            if (outputTerminal)
                outputTerminal.setData(data);
        }
        else if (typeof outputs === "number") {
            if (this.outputs[outputs])
                this.outputs[outputs].setData(data);
        }
        else {
            let outputData = new Map();
            Object.entries(outputs).forEach((entry) => {
                let terminal = this.outputs.find((term) => term.name === entry[0]);
                if (terminal)
                    outputData.set(terminal, entry[1]);
                else
                    throw Log.error("Terminal '" + entry[0] + "' not found");
            });
            let groupedConnectors = new Map();
            let outputDataIterator = outputData.keys();
            let curr = outputDataIterator.next().value;
            while (curr) {
                curr.connectors.forEach((connector) => {
                    if (groupedConnectors.has(connector.endNode))
                        groupedConnectors.get(connector.endNode).push(connector);
                    else
                        groupedConnectors.set(connector.endNode, [connector]);
                });
                curr = outputDataIterator.next().value;
            }
            let gCntrsIterator = groupedConnectors.values();
            let connectors = gCntrsIterator.next().value;
            while (connectors) {
                for (let i = 1; i < connectors.length; i++)
                    connectors[i].setData(outputData.get(connectors[i].start));
                connectors[0].data = outputData.get(connectors[0].start);
                connectors = gCntrsIterator.next().value;
            }
        }
        if (this.flow.state === FlowState.Idle) {
            this.flow.executionGraph.start();
        }
    }
    toggle() {
        this.renderState.nodeState =
            this.renderState.nodeState === NodeState.MAXIMIZED ? NodeState.MINIMIZED : NodeState.MAXIMIZED;
    }
    dispose() {
        this.flow.removeNode(this.id);
    }
    createUI(type, options) {
        const uiNode = UINode.create(type, this, options);
        return uiNode;
    }
    onDown(screenPos, realPos) {
        this.call("down", this, screenPos, realPos);
        let hitColor = Color.rgbaToString(this.flow.flowConnect.offUIContext.getImageData(screenPos.x, screenPos.y, 1, 1).data);
        this.currHitUINode = this.getHitUINode(hitColor);
        this.currHitUINode && this.currHitUINode.sendEvent("down", { screenPos, realPos, target: this.currHitUINode });
        let hitTerminal = this.getHitTerminal(hitColor, screenPos, realPos);
        if (hitTerminal) {
            this.currHitTerminal = hitTerminal;
            this.currHitTerminal.onDown(screenPos, realPos);
        }
    }
    onOver(screenPos, realPos) {
        this.call("over", this, screenPos, realPos);
        let hitColor = Color.rgbaToString(this.flow.flowConnect.offUIContext.getImageData(screenPos.x, screenPos.y, 1, 1).data);
        let hitTerminal = this.getHitTerminal(hitColor, screenPos, realPos);
        if (hitTerminal !== this.prevHitTerminal) {
            this.prevHitTerminal && this.prevHitTerminal.onExit(screenPos, realPos);
            hitTerminal && hitTerminal.onEnter(screenPos, realPos);
        }
        else {
            hitTerminal && !this.currHitTerminal && hitTerminal.onOver(screenPos, realPos);
        }
        this.prevHitTerminal = hitTerminal;
        let hitUINode = this.getHitUINode(hitColor);
        if (hitUINode !== this.prevHitUINode) {
            this.prevHitUINode && this.prevHitUINode.sendEvent("exit", { screenPos, realPos, target: this.prevHitUINode });
            hitUINode && hitUINode.sendEvent("enter", { screenPos, realPos, target: hitUINode });
        }
        else {
            hitUINode && !this.currHitUINode && hitUINode.sendEvent("over", { screenPos, realPos, target: hitUINode });
        }
        this.prevHitUINode = hitUINode;
    }
    onEnter(screenPosition, realPosition) {
        this.call("enter", this, screenPosition, realPosition);
    }
    onExit(screenPosition, realPosition) {
        this.call("exit", this, screenPosition, realPosition);
        let hitColor = Color.rgbaToString(this.flow.flowConnect.offUIContext.getImageData(screenPosition.x, screenPosition.y, 1, 1).data);
        let hitTerminal = this.getHitTerminal(hitColor, screenPosition, realPosition);
        hitTerminal && hitTerminal.onExit(screenPosition, realPosition);
        this.prevHitTerminal && this.prevHitTerminal.onExit(screenPosition, realPosition);
        this.prevHitTerminal = null;
        this.currHitTerminal && this.currHitTerminal.onExit(screenPosition, realPosition);
    }
    onUp(screenPos, realPos) {
        this.call("up", this, screenPos, realPos);
        let hitColor = Color.rgbaToString(this.flow.flowConnect.offUIContext.getImageData(screenPos.x, screenPos.y, 1, 1).data);
        this.currHitUINode = null;
        let hitUINode = this.getHitUINode(hitColor);
        hitUINode &&
            hitUINode.sendEvent("up", { screenPos: screenPos.clone(), realPos: realPos.clone(), target: hitUINode });
        let hitTerminal = this.getHitTerminal(hitColor, screenPos, realPos);
        hitTerminal && hitTerminal.onUp(screenPos, realPos);
    }
    onClick(screenPos, realPos) {
        this.call("click", this, screenPos, realPos);
        let hitColor = Color.rgbaToString(this.flow.flowConnect.offUIContext.getImageData(screenPos.x, screenPos.y, 1, 1).data);
        if (realPos.y < this.position.y + this.style.titleHeight * this.flow.flowConnect.scale) {
            let hitNodeButton = this.getHitNodeButton(hitColor);
            hitNodeButton && hitNodeButton.onClick(this);
        }
        else {
            this.currHitTerminal && this.currHitTerminal.onClick(screenPos, realPos);
            let hitUINode = this.getHitUINode(hitColor);
            hitUINode &&
                hitUINode.sendEvent("click", { screenPos: screenPos.clone(), realPos: realPos.clone(), target: hitUINode });
        }
    }
    onDrag(screenPos, realPos) {
        this.call("drag", this, screenPos, realPos);
        let hitColor = Color.rgbaToString(this.flow.flowConnect.offUIContext.getImageData(screenPos.x, screenPos.y, 1, 1).data);
        let hitUINodeWhileDragging = this.getHitUINode(hitColor);
        if (this.currHitUINode && this.currHitUINode.draggable) {
            if (hitUINodeWhileDragging === this.currHitUINode) {
                this.currHitUINode.sendEvent("drag", { screenPos, realPos, target: this.currHitUINode });
            }
            else {
                this.currHitUINode.sendEvent("exit", { screenPos, realPos, target: this.currHitUINode });
                this.currHitUINode = null;
                this.flow.flowConnect.currHitNode = null;
                this.flow.flowConnect.pointers = [];
            }
        }
    }
    onContextMenu(screenPos, realPos) {
        this.call("rightclick", this);
        if (this.currHitUINode)
            this.currHitUINode.sendEvent("context-menu", { screenPos, realPos, target: this.currHitUINode });
    }
    onWheel(direction, screenPos, realPos) {
        this.call("wheel", this, direction, screenPos, realPos);
        let hitColor = Color.rgbaToString(this.flow.flowConnect.offUIContext.getImageData(screenPos.x, screenPos.y, 1, 1).data);
        let hitUINode = this.getHitUINode(hitColor);
        hitUINode &&
            hitUINode.zoomable &&
            hitUINode.sendEvent("wheel", { screenPos, realPos, target: hitUINode, direction });
    }
    serializeState(state, persist) {
        return __awaiter(this, void 0, void 0, function* () {
            for (let key in state) {
                if (state[key] instanceof File) {
                    if (persist) {
                        const id = uuid();
                        const meta = yield persist(id, state[key]);
                        state[key] = Object.assign({ id: `raw##${id}` }, meta);
                    }
                    else {
                        state[key] = null;
                    }
                }
                else if (state[key] instanceof Vector) {
                    state[key] = state[key].serialize();
                }
                else if (state[key] instanceof AudioBuffer) {
                    state[key] = null;
                }
                else if (typeof state[key] === "object") {
                    state[key] = yield this.serializeState(state[key]);
                }
            }
            return state;
        });
    }
    serialize(persist) {
        return __awaiter(this, void 0, void 0, function* () {
            const serializedState = yield this.serializeState(Object.assign({}, this.state), persist);
            return Promise.resolve({
                id: this.id,
                name: this.name,
                type: this.type,
                position: this.position.serialize(),
                width: this.width,
                state: serializedState,
                inputs: this.inputs.map((terminal) => terminal.serialize()),
                outputs: this.outputs.map((terminal) => terminal.serialize()),
                style: this.style,
                hitColor: this.hitColor.serialize(),
                zIndex: this.zIndex,
                focused: this.focused,
                renderState: this.renderState,
            });
        });
    }
}
export var NodeState;
(function (NodeState) {
    NodeState["MAXIMIZED"] = "Maximized";
    NodeState["MINIMIZED"] = "Minimized";
})(NodeState || (NodeState = {}));
const DefaultNodeStyle = () => ({
    font: "arial",
    fontSize: ".75rem",
    titleFont: "arial",
    titleFontSize: ".85rem",
    color: "#000",
    titleColor: "#000",
    maximizeButtonColor: "darkgrey",
    nodeButtonSize: 10,
    nodeButtonSpacing: 5,
    expandButtonColor: "#000",
    minimizedTerminalColor: "green",
    outlineColor: "#000",
    padding: 10,
    spacing: 10,
    rowHeight: 20,
    titleHeight: 29,
    terminalRowHeight: 24,
    terminalStripMargin: 8,
});
const DefaultNodeOptions = () => ({
    name: "New Node",
    width: 100,
    style: {},
    state: {},
    id: uuid(),
});
export class NodeButton extends Hooks {
    constructor(node, onClick, renderer, align, style) {
        super();
        this.node = node;
        this.onClick = onClick;
        this.align = align;
        this.style = {};
        this.deltaX = 0;
        this.style = style !== null && style !== void 0 ? style : {};
        this.setHitColor();
        this.renderer = renderer;
    }
    setHitColor() {
        let color = Color.Random();
        while (this.node.nodeButtons.get(color.rgbaString))
            color = Color.Random();
        this.hitColor = color;
        this.node.nodeButtons.set(this.hitColor.rgbaString, this);
    }
    render() {
        this.node.context.save();
        this.renderer(this.node.context, this.getRenderParams(), this);
        this.node.context.restore();
        this.node.offUIContext.save();
        this._offUIRender();
        this.node.offUIContext.restore();
        this.call("render", this);
    }
    getRenderParams() {
        let position = this.node.position.serialize();
        position.x += this.deltaX;
        position.y += this.node.style.titleHeight / 2 - this.node.style.nodeButtonSize / 2;
        return { position };
    }
    _offUIRender() {
        this.node.offUIContext.fillStyle = this.hitColor.rgbaCSSString;
        this.node.offUIContext.fillRect(this.node.position.x + this.deltaX, this.node.position.y + this.node.style.titleHeight / 2 - this.node.style.nodeButtonSize / 2, this.node.style.nodeButtonSize, this.node.style.nodeButtonSize);
    }
}
