var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Color } from "../flow-connect.js";
import { Vector } from "./vector.js";
import { Node } from "./node.js";
import { Hooks } from "./hooks.js";
import { Group } from "./group.js";
import { Connector } from "./connector.js";
import { AVLTree } from "../utils/avl-tree.js";
import { SubFlowNode } from "./subflow-node.js";
import { capitalize, isVector, uuid } from "../utils/utils.js";
import { Graph } from "./graph.js";
import { Log } from "../utils/logger.js";
export class Flow extends Hooks {
    constructor(flowConnect) {
        super();
        this.flowConnect = flowConnect;
        this.globalEvents = new Hooks();
        this.parentFlow = null;
        this.listeners = {};
        this.renderers = {};
        this.nodes = new Map();
        this.groups = [];
        this.connectors = new Map();
        this.nodeHitColors = new Map();
        this.groupHitColors = new Map();
        this.sortedNodes = new AVLTree((a, b) => a.zIndex - b.zIndex, (node) => node.id);
        this.inputs = [];
        this.outputs = [];
        this.executionGraph = new Graph();
        flowConnect.on("tick", () => {
            if (this.state === FlowState.Running)
                this.call("tick", this);
        });
    }
    get state() {
        return this.executionGraph.state;
    }
    static create(flowConnect, options = DefaultFlowOptions()) {
        const flow = new Flow(flowConnect);
        const { name = "New Flow", rules = {}, ruleColors = {}, id = uuid() } = options;
        flow.name = name;
        flow.rules = Object.assign(Object.assign({}, DefaultRules()), rules);
        flow.ruleColors = Object.assign(Object.assign({}, DefaultRuleColors()), ruleColors);
        flow.id = id;
        return flow;
    }
    transform() {
        [...this.nodes.values()].forEach((node) => node.call("transform", node));
        [...this.groups.values()].forEach((group) => group.call("transform", group));
    }
    existsInFlow(flow) {
        for (let node of [...this.nodes.values()]) {
            if (node.subFlow === flow)
                return true;
            else if (node.subFlow) {
                return node.subFlow.existsInFlow(flow);
            }
        }
        return false;
    }
    addInput(name, dataType, position = Vector.Zero()) {
        return this._addIO("input", name, position, dataType);
    }
    addOutput(name, dataType, position) {
        return this._addIO("output", name, position, dataType);
    }
    _addIO(type, name, position, dataType) {
        const ioNode = this.createNode("core/tunnel", position, {
            tunnelType: type,
            name: capitalize(type),
            tunnelName: name,
            tunnelDataType: dataType,
        });
        (type === "input" ? this.inputs : this.outputs).push(ioNode);
        this.call(`add-${type}`, this, ioNode);
        return ioNode;
    }
    addSubFlow(subFlow, position = Vector.Zero()) {
        if (subFlow.parentFlow) {
            Log.error("Provided flow is already a sub-flow, a sub-flow cannot have multiple parent flows");
            return null;
        }
        const node = this.createNode("core/subflow", position, {
            name: subFlow.name,
            width: 150,
            subFlow: subFlow,
        });
        return node;
    }
    removeSubFlow(arg1) {
        let subFlowNode = null;
        if (arg1 instanceof Flow) {
            subFlowNode = [...this.nodes.values()]
                .filter((node) => node.type === "core/subflow")
                .find((node) => node.subFlow === arg1);
            this.removeNode(subFlowNode);
        }
        else if (arg1 instanceof SubFlowNode) {
            subFlowNode = arg1;
        }
        this.removeNode(subFlowNode);
    }
    createNode(type, position, options) {
        const node = Node.create(type, this, position, options);
        this.addNode(node);
        return node;
    }
    _createNode(type, position, options) {
        const node = Node.create(type, this, position, options, true);
        this.addNode(node);
        return node;
    }
    addNode(node) {
        this.nodes.set(node.id, node);
        this.sortedNodes.add(node);
        this.executionGraph.add(node);
    }
    removeNode(nodeOrID) {
        let node = typeof nodeOrID === "string" ? this.nodes.get(nodeOrID) : nodeOrID;
        if (!node)
            return;
        [...node.inputs, ...node.outputs, ...node.inputsUI, ...node.outputsUI]
            .filter((terminal) => terminal.isConnected())
            .forEach((terminal) => terminal.disconnect());
        if (node.group) {
            node.group.nodes.splice(node.group.nodes.findIndex((currNode) => currNode.id === node.id), 1);
        }
        this.nodes.delete(node.id);
        this.sortedNodes.remove(node);
        this.executionGraph.remove(node);
    }
    removeConnector(id) {
        if (this.connectors.get(id) === this.floatingConnector)
            this.floatingConnector = null;
        this.connectors.delete(id);
    }
    removeAllFocus() {
        this.nodes.forEach((node) => (node.focused = false));
    }
    render() {
        this.groups.forEach((group) => group.render());
        this.connectors.forEach((connector) => connector.render());
        this.sortedNodes.forEach((node) => node.render());
        this.call("render", this);
    }
    start() {
        if (this.state !== FlowState.Stopped)
            return;
        this.executionGraph.start();
        this.call("start", this);
    }
    stop() {
        if (this.state === FlowState.Stopped)
            return;
        for (let order = this.executionGraph.nodes.length - 1; order >= 0; order -= 1) {
            this.executionGraph.nodes[order]
                .filter((graphNode) => graphNode.flowNode instanceof SubFlowNode)
                .map((graphNode) => graphNode.flowNode)
                .forEach((subFlowNode) => subFlowNode.subFlow.stop());
        }
        this.executionGraph.stop();
        this.call("stop", this);
    }
    setFloatingConnector(floatingPos, fixedEnd, type) {
        const start = type === "left" ? fixedEnd : null;
        const end = type === "left" ? null : fixedEnd;
        let connector = Connector.create(this, start, end, { floatingTip: floatingPos });
        this.connectors.set(connector.id, connector);
        this.floatingConnector = connector;
    }
    removeFloatingConnector() {
        if (!this.floatingConnector)
            return;
        let terminal = null;
        if (this.floatingConnector.start)
            terminal = this.floatingConnector.start;
        else
            terminal = this.floatingConnector.end;
        if (terminal.node.currHitTerminal) {
            terminal.node.currHitTerminal.onExit(null, null);
            terminal.node.currHitTerminal = null;
        }
        this.removeConnector(this.floatingConnector.id);
        return terminal;
    }
    static deSerializeState(state, receive) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            for (let key in state) {
                if (isVector(state[key])) {
                    state[key] = Vector.create(state[key].x, state[key].y);
                }
                else if (Array.isArray(state[key]) && state[key].length > 0 && isVector(state[key][0])) {
                    state[key] = state[key].map((sv) => Vector.create(sv.x, sv.y));
                }
                else if (typeof state[key] === "object" && state[key] && ((_a = state[key].id) === null || _a === void 0 ? void 0 : _a.startsWith("raw##"))) {
                    if (receive) {
                        state[key] = yield receive(Object.assign(Object.assign({}, state[key]), { id: state[key].id.replace("raw##", "") }));
                    }
                    else {
                        state[key] = null;
                    }
                }
            }
            return state;
        });
    }
    serialize(persist) {
        return __awaiter(this, void 0, void 0, function* () {
            const nodes = yield Promise.all([...this.nodes.values()].map((node) => node.serialize(persist)));
            const inputs = yield Promise.all(this.inputs.map((input) => input.serialize()));
            const outputs = yield Promise.all(this.outputs.map((output) => output.serialize()));
            const ruleColors = {};
            Object.keys(this.ruleColors).forEach((key) => { var _a; return (ruleColors[key] = ((_a = this.ruleColors[key]) !== null && _a !== void 0 ? _a : Color.Random()).serialize()); });
            return Promise.resolve({
                version: this.flowConnect.version,
                id: this.id,
                name: this.name,
                rules: this.rules,
                ruleColors,
                nodes,
                groups: this.groups.map((group) => group.serialize()),
                connectors: [...this.connectors.values()].map((connector) => connector.serialize()),
                inputs,
                outputs,
            });
        });
    }
    static deSerialize(flowConnect, data, receive) {
        return __awaiter(this, void 0, void 0, function* () {
            const ruleColors = {};
            Object.keys(data.ruleColors).forEach((key) => (ruleColors[key] = Color.create(data.ruleColors[key])));
            let flow = Flow.create(flowConnect, {
                name: data.name,
                rules: data.rules,
                ruleColors,
                id: data.id,
            });
            for (let serializedNode of data.nodes) {
                const state = yield this.deSerializeState(serializedNode.state, receive);
                if (serializedNode.type === "core/subflow") {
                    const subFlow = yield Flow.deSerialize(flowConnect, serializedNode.subFlow, receive);
                    serializedNode.subFlow = subFlow;
                }
                const node = flow._createNode(serializedNode.type, Vector.create(serializedNode.position), Object.assign(Object.assign({}, serializedNode), { state, hitColor: Color.create(serializedNode.hitColor) }));
                if (serializedNode.type === "core/tunnel") {
                    (serializedNode.tunnelType === "input" ? flow.inputs : flow.outputs).push(node);
                }
            }
            data.groups.forEach((serializedGroup) => {
                let group = Group.create(flow, Vector.create(serializedGroup.position), Object.assign(Object.assign({}, serializedGroup), { hitColor: Color.create(serializedGroup.hitColor) }));
                serializedGroup.nodes.forEach((nodeId) => group.add(flow.nodes.get(nodeId)));
                flow.groups.push(group);
            });
            data.connectors.forEach((serializedConnector) => {
                let startNode = flow.nodes.get(serializedConnector.startNodeId);
                let startTerminal;
                if (typeof serializedConnector.startId === "string") {
                    startTerminal = startNode.outputs.find((terminal) => terminal.id === serializedConnector.startId);
                }
                else {
                    startTerminal = startNode.outputsUI[serializedConnector.startId];
                }
                let endNode = flow.nodes.get(serializedConnector.endNodeId);
                let endTerminal;
                if (typeof serializedConnector.endId === "string") {
                    endTerminal = endNode.inputs.find((terminal) => terminal.id === serializedConnector.endId);
                }
                else {
                    endTerminal = endNode.inputsUI[serializedConnector.endId];
                }
                const connector = Connector.create(flow, startTerminal, endTerminal, {
                    id: serializedConnector.id,
                    style: serializedConnector.style,
                });
                flow.connectors.set(serializedConnector.id, connector);
            });
            return Promise.resolve(flow);
        });
    }
}
export var FlowState;
(function (FlowState) {
    FlowState["Running"] = "Running";
    FlowState["Idle"] = "Idle";
    FlowState["Stopped"] = "Stopped";
})(FlowState || (FlowState = {}));
const DefaultFlowOptions = () => ({
    name: "New Flow",
    rules: DefaultRules(),
    ruleColors: {},
});
const DefaultRules = () => ({
    string: ["string", "any"],
    number: ["number", "audioparam", "any"],
    boolean: ["boolean", "any"],
    array: ["array", "any"],
    file: ["file", "any"],
    event: ["event", "any"],
    vector: ["vector", "any"],
    "array-buffer": ["array-buffer", "any"],
    audio: ["audio", "audioparam"],
    audioparam: ["audioparam"],
    "audio-buffer": ["audio-buffer", "any"],
    any: ["any"],
});
const DefaultRuleColors = () => ({
    string: Color.create("#B2F77D"),
    number: Color.create("#C9A185"),
    boolean: Color.create("#EADFC7"),
    array: Color.create("#3484F0"),
    file: Color.create("#EEEFF7"),
    event: Color.create("#9E64E8"),
    vector: Color.create("#DCC68D"),
    "array-buffer": Color.create("#FF6BCE"),
    audio: Color.create("#FFD154"),
    audioparam: Color.create("#FF0F50"),
    "audio-buffer": Color.create("#C3D4C2"),
    any: Color.create("#FFFBF9"),
});
