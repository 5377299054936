var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Node } from "./node.js";
import { TerminalType } from "./terminal.js";
export class TunnelNode extends Node {
    constructor(_flow, options) {
        super();
        this.tunnelType = options.tunnelType;
    }
    get proxyTerminal() {
        return this._proxyTerminal;
    }
    set proxyTerminal(terminal) {
        this._proxyTerminal = terminal;
        this._proxyTerminal.on("data", (_, data) => this.outputs[0].setData(data));
    }
    setupIO(options) {
        this.addTerminal({
            type: options.tunnelType === "input" ? TerminalType.OUT : TerminalType.IN,
            name: options.tunnelName,
            dataType: options.tunnelDataType,
        });
    }
    created() {
        if (this.tunnelType === "input") {
            this.outputs[0].on("connect", (_, connector) => {
                if (this.proxyTerminal && this.proxyTerminal.connectors.length > 0) {
                    connector.data = this.proxyTerminal.connectors[0].data;
                }
            });
        }
        else {
            this.inputs[0].on("data", (_, data) => this.proxyTerminal.setData(data));
        }
    }
    process() {
        var _a;
        (_a = this.outputs[0]) === null || _a === void 0 ? void 0 : _a.setData(this.proxyTerminal.getData());
    }
    serialize(persist) {
        const _super = Object.create(null, {
            serialize: { get: () => super.serialize }
        });
        return __awaiter(this, void 0, void 0, function* () {
            const serializedNode = yield _super.serialize.call(this, persist);
            return Object.assign(Object.assign({}, serializedNode), { tunnelType: this.tunnelType });
        });
    }
}
